import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Box, Tabs, Tab, Divider, Container } from "@material-ui/core"
import Helmet from "react-helmet"
import SwipeableViews from "react-swipeable-views"

import MiniDrawer from "../components/common/MiniDrawer"
import PageHeader from "../components/common/PageHeader"
import TabPanel from "../components/common/TabPanel"

import { APP_NAME } from "../constants"

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  }
}))

const ListItem = (item, index) => {
  return (
    <Box key={index}>
      <p>{index}</p>
    </Box>
  )
}

const TestLayout = props => {
  const classes = useStyles()
  const { authed, history, user } = props
  
  const mobileView = useMediaQuery("(max-width:599px)")

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#tab1":
        return 0
      case "#tab2":
        return 1
    }
  })

  const handleTabChange = (event, newTab) => {
    history.push(`#${tabs[newTab].toLocaleLowerCase()}`)
    setCurrentTab(newTab)
  }

  const handleChangeTabIndex = index => {
    setCurrentTab(index)
  }

  const tabs = ["Tab 1", "Tab 2"]

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | User Access Control`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader title="Test Layout" pageName="test" />

      <Box className={classes.content}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />
          })}
        </Tabs>
        <Divider />
        <SwipeableViews
          index={currentTab}
          onChangeIndex={handleChangeTabIndex}
          animateTransitions={mobileView}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel className={classes.tabPanel} value={currentTab} index={0}>
            {new Array(10).fill(null).map((item, index) => {
              return ListItem(item, index)
            })}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={1}>
            {new Array(25).fill(null).map((item, index) => {
              return ListItem(item, index)
            })}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Container>
  )
}

export default TestLayout
