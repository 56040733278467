import _ from "lodash"
import { DateTime, Duration } from "luxon"

const template = () => {
  return new Promise((resolve, reject) => {
    try {
      // this
      resolve(null)
    } catch (e) {
      reject(null)
    }
  })
}

/**
 * Create a formatted array of transactions grouped by date and then user
 */
const orderByDateAndUser = transactions => {
  return new Promise((resolve, reject) => {
    try {
      const result = _.chain(transactions)
        .orderBy("createdAt", ["asc"]) // order by ascending date
        .groupBy(transaction => transaction.createdAt.split("T")[0]) // group by date
        .toPairs() // convert objects to arrays
        .map(item => {
          // assign array values to meaningful variables
          const date = item[0]
          const cardTransactions = item[1]

          // group transactions by userId
          const userTransactionsObject = _.chain(cardTransactions)
            .groupBy(transaction => transaction.authDevice.user._id)
            .toPairs()
            .map(userTransaction => {
              // assign array values to meaningful variables
              const userId = userTransaction[0]
              const userTransactions = userTransaction[1]

              // reformat user arrays as userId/transactions objects
              return { userId, userTransactions }
            })
            .value()

          // reformat date arrays as date/user objects
          return { date, users: userTransactionsObject }
        })
        .value()
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Create a formatted array
 */
const calculateElapsedTime = transactions => {
  return new Promise((resolve, reject) => {
    try {
      const result = []

      // Return each date object
      transactions.forEach(date => {
        // Iterate over users in date
        date.users.forEach(user => {
          // Iterate over user transactions
          let accumulatedTime = 0
          user.userTransactions.forEach((transaction, index) => {
            // If action == "clock-out" get diff from item -1
            if (transaction.action === "clock-out" && index !== 0) {
              const clockin = DateTime.fromISO(
                user.userTransactions[index - 1].createdAt
              )
              const clockout = DateTime.fromISO(transaction.createdAt)
              const elapsedTime = clockout.diff(clockin).as("milliseconds")
              accumulatedTime += elapsedTime
            }
          })

          const reportItem = {
            date: date.date,
            userId: user.userId,
            firstName: user.userTransactions[0].authDevice.user.firstName,
            lastName: user.userTransactions[0].authDevice.user.lastName,
            duration: Duration.fromMillis(accumulatedTime).toFormat("hh:mm")
          }

          result.push(reportItem)
        })
      })

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Create a formatted array
 */
const totalTimeByUserId = transactions => {
  return new Promise((resolve, reject) => {
    try {
      const results = _.chain(transactions)
        .groupBy("userId")
        .map(value => {
          return _.reduce(
            value,
            (result, currentObject) => {
              const accumulatedHours = Duration.fromObject({
                hours: result.hours.split(":")[0],
                minutes: result.hours.split(":")[1],
                seconds: 0
              })

              const currentHours = Duration.fromObject({
                hours: currentObject.duration.split(":")[0],
                minutes: currentObject.duration.split(":")[1],
                seconds: 0
              })

              const totalHours = accumulatedHours + currentHours

              // console.log({
              //   name: `${currentObject.firstName} ${currentObject.lastName}`,
              //   accumulatedHours: accumulatedHours.toFormat("hh:mm"),
              //   currentHours: currentHours.toFormat("hh:mm"),
              //   totalHours: Duration.fromMillis(totalHours).toFormat("hh:mm")
              // })

              return {
                name: `${currentObject.firstName} ${currentObject.lastName}`,
                hours: Duration.fromMillis(totalHours).toFormat("hh:mm")
              }
            },
            {
              name: "",
              hours: "00:00"
            }
          )
        })
        .filter(o => o.hours !== "00:00")
        .sortBy("name")
        .value()

      resolve(results)
    } catch (err) {
      reject(err)
    }
  })
}

export { template, orderByDateAndUser, calculateElapsedTime, totalTimeByUserId }
