import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface Props {
  actions: any
}

interface State {
  actionMenuAnchor: any
}

class ContextMenu extends React.Component<Props, State> {
  
  constructor(props: any) {
    super(props);

    this.state = {
      actionMenuAnchor: null
    }

  }

  handleMenuOpen = (event: any) => {
    this.setState({
      actionMenuAnchor: event.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      actionMenuAnchor: null
    });
  };

  render() {
    const { actions } = this.props;
    const { actionMenuAnchor } = this.state;
    const open = Boolean(actionMenuAnchor);

    return(
      <div>
        <IconButton 
          // variant="flat" 
          color="default" 
          aria-label="row item actions" 
          onClick={this.handleMenuOpen}>
            <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={actionMenuAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          elevation={1}
          open={open}
          onClose = {this.handleMenuClose}
        >
          { actions.map((action: any, index: number) => {
            return  <MenuItem 
                      key={index}
                      onClick={(event) => {
                          action.onClick(event);
                          this.handleMenuClose();
                        }}>
                      {action.name}
                    </MenuItem> 
            })
          }
        </Menu>
      </div>
    )
  }
}

export default ContextMenu;