import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { withRouter } from "react-router-dom"

import Helmet from "react-helmet"
import {
  Box,
  TableContainer,
  Typography,
  Tabs,
  Tab,
  Divider,
  Container
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"

import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import MiniDrawer from "../components/common/MiniDrawer"
import PageHeader from "../components/common/PageHeader"
import DataTable from "../components/common/DataTable.tsx"

import UserProfile from "../components/UserProfile"
import UserAuthDevices from "../components/UserAuthDevices"

import { APP_NAME } from "../constants"

import { hasRole, signOut } from "../utils/userHelper"
import TabPanel from "../components/common/TabPanel"
import SwipeableViews from "react-swipeable-views"

const GET_USER = gql`
  query user($_id: ID!, $includeAuthDevice: Boolean) {
    user(_id: $_id, includeAuthDevice: $includeAuthDevice) {
      _id
      firstName
      lastName
      clockedIn
      image
      type
      authDevices {
        _id
        type
        secret
      }
    }
  }
`

const GET_TRANSACTIONS = gql`
  query cardTransactions(
    $filterByTokens: [String]
    $includeAuthDevice: Boolean
  ) {
    cardTransactions(
      filterByTokens: $filterByTokens
      includeAuthDevice: $includeAuthDevice
    ) {
      _id
      token
      action
      reason
      authDevice {
        user {
          firstName
          lastName
        }
        type
      }
      createdAt
    }
  }
`

const tabs = ["Profile", "Devices", "Attendance"]

const UserView = props => {
  const classes = useStyles(props)
  const [edit, setEdit] = useState(null)
  const { authed, history, user } = props
  const userId = props.match.params.id

  const mobileView = useMediaQuery("(max-width:599px)")

  // Fetch the user"s data
  const { loading: userLoading, error: userError, data: userData } = useQuery(
    GET_USER,
    {
      variables: { _id: userId, includeAuthDevice: true }
    }
  )

  // Extract the token secrets from the user"s devices and add them to the tokens array
  const tokens = []
  if (userData !== undefined && userData.user.hasOwnProperty("authDevices")) {
    userData.user.authDevices.forEach(authDevice => {
      tokens.push(authDevice.secret)
    })
  }

  // If the userData is populated then fetch transactions for those tokens
  const {
    loading: transactionsLoading,
    error: transactionsError,
    data: transactionsData
  } = useQuery(GET_TRANSACTIONS, {
    skip: !userData, // tokens.length === 0,
    variables: { filterByTokens: tokens, includeAuthDevice: true }
  })

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#profile":
        return 0
      case "#devices":
        return 1
      case "#attendance":
        return 2
    }
  })

  const handleTabChange = (event, newTab) => {
    history.push(`#${tabs[newTab].toLocaleLowerCase()}`)
    setCurrentTab(newTab)
  }

  const handleChangeTabIndex = index => {
    setCurrentTab(index)
  }

  const Attendance = ({ transactions }) => {
    return (
      <Container>
        <Box className={classes.header}>
          <Typography variant="h6" component="h6">
            Attendance
          </Typography>
        </Box>
        <TableContainer className={classes.attendance}>
          <DataTable
            pagination
            paginationIntervals={[5, 10, 20, 50, 100]}
            rowHoverHightlight
            filterColumn=""
            searchQuery=""
            headers={["#", "Timestamp", "Type", "Action", "Reason"]}
            columns={[
              {
                type: "index"
              },
              {
                type: "date",
                value: "createdAt",
                format: "dd/MM/yyyy, HH:mm:ss"
              },
              {
                type: "key",
                value: "['authDevice']['type']"
              },
              {
                type: "key",
                value: "action"
              },
              {
                type: "key",
                value: "reason"
              }
            ]}
            rowActions={row => {
              return null
            }}
            data={transactions}
          />
        </TableContainer>
      </Container>
    )
  }

  if (userError) {
    if (userError.message.includes("token is invalid")) {
      signOut()
    }
  }

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | User Access Control`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader
        title="Profile"
        pageName={
          userData ? `${userData.user.firstName} ${userData.user.lastName}` : ""
        }
      />

      <Box className={classes.tabContainer}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />
          })}
        </Tabs>
        <Divider />
      </Box>

      <Box className={classes.content}>
        {userLoading && <p>Loading...</p>}
        {userError && <p>Unable to load data!</p>}
        <SwipeableViews
          index={currentTab}
          animateTransitions={mobileView}
          onChangeIndex={handleChangeTabIndex}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel value={currentTab} index={0}>
            {!userLoading && !userError && <UserProfile user={userData.user} />}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {!userLoading && !userError && (
              <UserAuthDevices user={userData.user} />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            {transactionsLoading && currentTab === 2 && <p>Loading...</p>}
            {!transactionsLoading &&
              !transactionsError &&
              transactionsData &&
              currentTab === 2 && (
                <Attendance transactions={transactionsData.cardTransactions} />
              )}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(3)
    }
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  attendance: {
    // display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
    // maxWidth: 520 + "px"
  },
  // tabContainer: {
  //   marginLeft: theme.spacing(6),
  //   marginBottom: theme.spacing(4),
  //   [theme.breakpoints.down("xs")]: {
  //     marginLeft: theme.spacing(0)
  //   }
  // },
  tabs: {
    marginLeft: theme.spacing(12),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    minWidth: "inherit",
    marginRight: `${28}px`
  }
}))

const hasRouterPage = withRouter(UserView)
export default hasRouterPage
