import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { FormHelperText, Typography } from "@material-ui/core"
import DataDialog from "./common/DataDialog.tsx"

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      _id
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  }
}))

const ChangePasswordDialog = props => {
  const classes = useStyles()
  const { open, onClose } = props

  const [values, setValues] = React.useState({
    loading: false,
    success: false,
    currentPassword: "",
    newPassword: "",
    showPassword: false,
    errorMessage: null
  })

  const [changePassword, { data }] = useMutation(CHANGE_PASSWORD)

  const handleClose = () => {
    onClose()
    setValues({
      ...values,
      currentPassword: "",
      newPassword: "",
      success: false,
      loading: false,
      showPassword: false,
      errorMessage: null
    })
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorMessage: null })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleChangePassword = () => {
    setValues({ ...values, loading: true })
    changePassword({
      variables: {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      }
    })
      .then(() => {
        setValues({
          ...values,
          currentPassword: "",
          newPassword: "",
          errorMessage: null,
          loading: false,
          success: true
        })
      })
      .catch(err => {
        let message = ""
        if (err.graphQLErrors.length > 0) {
          message = err.graphQLErrors[0].message
        } else {
          message = err.message
        }
        setValues({
          ...values,
          errorMessage: message,
          loading: false
        })
      })
  }

  if (open) {
    return (
      <DataDialog
        open
        title="Change Password"
        content={
          !values.success ? (
            <div className={classes.formContainer}>
              <FormControl
                error={!!values.errorMessage}
                className={clsx(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="standard-adornment-currentpassword">
                  Current Password
                </InputLabel>
                <Input
                  id="standard-adornment-currentpassword"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("currentPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="currentpassword-helper-text">
                  {values.errorMessage}
                </FormHelperText>
              </FormControl>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-newpassword">
                  New Password
                </InputLabel>
                <Input
                  id="standard-adornment-newpassword"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("newPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          ) : (
            <Typography>Password changed sucessfully :-)</Typography>
          )
        }
        actions={[
          {
            name: "Save",
            disabled: values.success,
            primary: true,
            loading: values.loading,
            onClick: () => handleChangePassword()
          },
          {
            name: !values.success ? "Cancel" : "Close",
            primary: false,
            onClick: () => handleClose()
          }
        ]}
      />
    )
  }
  return null
}

export default ChangePasswordDialog
