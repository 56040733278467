import React from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

import { getUser } from "./redux/actions"
import { hasRole } from "./utils/userHelper"

import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import UsersView from "./pages/UsersView"
import UserView from "./pages/UserView"
import AttendanceView from "./pages/AttendanceView"
import SettingsView from "./pages/SettingsView.tsx"
import PunchCardView from "./pages/PunchCardView"
import TestLayout from "./pages/TestLayout"
import PushNotifications from "./components/PushNotifications"

import "./App.css"

const isServer = typeof window === "undefined"

class App extends React.Component {
  constructor(props) {
    super(props)
    // localStorage.setItem('authed', 'true');

    this.state = {
      authed: !!localStorage.getItem("token"),
      roles: []
    }
  }

  renderSwitch = () => {
    const { authed, roles } = this.state

    return (
      <>
      <PushNotifications authed={authed} />
      <Switch>
        <PublicRoute exactly path="/login" component={Login} authed={authed} />
        <PrivateRoute
          exactly
          path="/users/:id"
          component={UserView}
          authed={authed}
          role
        />
        <PrivateRoute
          exactly
          path="/users"
          component={UsersView}
          authed={authed}
          role
        />

        <PrivateRoute
          exactly
          path="/attendance"
          component={AttendanceView}
          authed={authed}
          role
        />
        <PrivateRoute
          exactly
          path="/settings"
          component={SettingsView}
          authed={authed}
          role
        />

        <PublicRoute
          exactly
          path="/punchcard"
          component={PunchCardView}
          authed={authed}
          role
        />
        {/* <PrivateRoute
          exactly
          path="/test"
          component={TestLayout}
          authed={authed}
          role
        /> */}
        <PrivateRoute
          exactly
          path="/"
          component={Dashboard}
          authed={authed}
          role
        />
      </Switch>
      </>
    )
  }

  render() {
    if (isServer) {
      const { StaticRouter } = require("react-router")
      return (
        <StaticRouter location={this.props.router.asPath}>
          {this.renderSwitch()}
        </StaticRouter>
      )
    }
    return <BrowserRouter>{this.renderSwitch()}</BrowserRouter>
  }
}

const PublicRoute = ({ component: Component, authed, ...rest }) => (
  <Route {...rest} render={props => <Component authed {...props} />} />
)

const PrivateRoute = ({ component: Component, authed, role, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authed && role ? (
        <Component authed {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export default App
