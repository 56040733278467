import * as types from "../types"

const INITIAL_STATE = {
  id: null,
  roles: []
  // displayName: '',
  // emailAddress: '',
  // image: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, ...action.user }

    case types.SET_PROFILE:
      return { ...state, ...action.profile }

    case types.RESET_APP:
      return INITIAL_STATE

    default:
      return state
  }
}
