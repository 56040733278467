import React, { Component, Fragment } from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TextField,
  InputAdornment,
  Toolbar,
  Theme,
  Typography
} from "@material-ui/core";

export interface Props extends WithStyles<typeof styles> {
  props: any;
  state: object;
  count: number;
  classes: any;

  pagination: boolean;
  search: boolean;
  paginationIntervals: Array<number>;
  rowHoverHightlight: boolean;
  headers: Array<string>;
  columns: Array<string>;
  data: Array<object>;
  onSearchQuery: Function;
  searchQuery: string;
  filterColumn: string;
  filterRange: Object;
  // onRowClick: any
  rowActions: any;
}

export interface State {
  rowsPerPage: number;
  page: number;
  data: Array<object>;
  order: string;
  orderBy: string;
  searchQuery: string;
  selected: Array<string>;
}

class DataTable extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      rowsPerPage: props.data.length || 0,
      page: 0,
      order: "asc",
      orderBy: "name",
      selected: [],
      searchQuery: props.data.searchQuery,
      data: props.data || []
    };
  }

  parseColumn = (column: any, row: any, rowIndex: number) => {
    switch (column.type) {
      case "index":
        return rowIndex;
      case "date":
        if (column.format === undefined) {
          return DateTime.fromISO(row[column.value])
            .toLocaleString(DateTime.DATETIME_MED)
            .toString();
        } else {
          return DateTime.fromISO(row[column.value])
            .toFormat(column.format)
            .toString();
        }
      case "key":
        return column.value.includes("[")
          ? eval("row" + column.value)
          : row[column.value];
      case "string":
        return [column.value];
      case "boolean":
        if (column.valueTrue !== undefined && column.valueTrue !== undefined) {
          return row[column.value] === true
            ? column.valueTrue
            : column.valueFalse;
        }
        return column.value === true ? "true" : "false";
      default:
        return column.includes("[") ? eval("row" + column) : row[column];
    }
  };

  componentWillUpdate(nextProps: any, prevState: any) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        rowsPerPage: nextProps.data.length
      });
    }
  }

  renderSearch = () => {
    const { classes } = this.props;

    return (
      <TextField
        className={classes.searchInput}
        id="search"
        // onChange={(text) => this.props.onSearchQuery(text)}
        onChange={event => this.setState({ searchQuery: event.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    );
  };

  renderFilterRange = () => {
    const { classes } = this.props;
    const { filterRange } = this.props;

    if (filterRange.hasOwnProperty("date")) {
      return (
        <div>
          {/* <TextField
            className={classes.searchInput}
            id="filter-date-range"
            onChange={(event) => this.setState({ searchQuery: event.target.value })}
            placeholder={"Select date range.."}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          /> */}
        </div>
      );
    }

    return (
      <div>
        <TextField
          className={classes.searchInput}
          id="filter-range-start"
          onChange={event => this.setState({ searchQuery: event.target.value })}
          placeholder={"Start"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <TextField
          className={classes.searchInput}
          id="filter-range-start"
          onChange={event => this.setState({ searchQuery: event.target.value })}
          placeholder={"End"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </div>
    );
  };

  renderPagination = () => {
    const { classes, paginationIntervals } = this.props;
    const { data, page, rowsPerPage } = this.state;
    const self = this;

    function handleChangePage(event: any, page: number) {
      self.setState({
        page
      });
    }

    function handleChangeRowsPerPage(event: any) {
      self.setState({
        rowsPerPage: event.target.value
      });
    }

    return (
      <TablePagination
        rowsPerPageOptions={paginationIntervals}
        component="div"
        classes={{
          toolbar: classes.paginationToolbar
        }}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    );
  };

  renderNoData = () => {
    const { classes } = this.props;
    const { data } = this.state;

    if (data.length < 1) {
      return (
        <Fragment>
          <Typography className={classes.noData}>No Data...</Typography>
        </Fragment>
      );
    }
  };

  render() {
    const {
      classes,
      search,
      pagination,
      headers,
      columns,
      filterColumn,
      rowActions,
      rowHoverHightlight,
      filterRange
    } = this.props;
    const { data, page, searchQuery } = this.state;

    const rowsPerPage =
      this.state.rowsPerPage === data.length
        ? data.length
        : this.state.rowsPerPage;

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className="DataTable">
        {(pagination || search) && (
          <Toolbar
            className={`${classes.toolbar} ${
              search ? classes.toolbarWithSearch : null
            }`}
          >
            {search ? this.renderSearch() : null}
            {pagination ? this.renderPagination() : null}
            {filterRange ? this.renderFilterRange() : null}
          </Toolbar>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header: string, colHeaderIndex: number) => {
                return (
                  <TableCell
                    key={colHeaderIndex}
                    className={classes.tableHeader}
                  >
                    {header}
                  </TableCell>
                );
              })}
              {rowActions !== null && <TableCell align={"right"}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.filter(data, (o: any) => {
              if (
                o.hasOwnProperty(filterColumn) &&
                o[filterColumn] !== undefined
              ) {
                return o[filterColumn].toUpperCase();
                //   // .indexOf(this.state.searchQuery.toUpperCase()) > -1;
                // console.log("gibby", o[filterColumn]);
                // return o;
              } else {
                return {};
              }
            })
              // data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, rowIndex: number) => {
                rowIndex = rowIndex + 1;
                if (page > 0) {
                  rowIndex = page * rowsPerPage + rowIndex;
                }

                return (
                  <TableRow key={rowIndex} hover={rowHoverHightlight || false}>
                    {columns.map((column: any, cellIndex: number) => {
                      return (
                        <TableCell key={cellIndex}>
                          {this.parseColumn(column, row, rowIndex)}
                        </TableCell>
                      );
                    })}
                    {rowActions !== null && (
                      <TableCell align={"right"}>
                        {rowActions(row, rowIndex)}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 57 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {this.renderNoData()}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    DataTable: {
      flex: 1
    },
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: 0 + "px",
      paddingRight: 0 + "px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start"
      }
    },
    toolbarWithSearch: {
      justifyContent: "space-between"
    },
    paginationToolbar: {
      paddingLeft: 0 + "px"
    },
    searchInput: {
      [theme.breakpoints.down("sm")]: {
        width: 100 + "%"
      }
    },
    tableHeader: {
      textTransform: "uppercase",
      fontWeight: "bold"
    },
    noData: {
      display: "flex",
      // color: "grey",//theme.palette.secondary,
      color: "grey",
      fontSize: 10
    }
  });

export default withStyles(styles)(DataTable);
