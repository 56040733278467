import React from "react"
import { DateTime } from "luxon"

import { Box, Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const TransactionItem = props => {
  const classes = useStyles(props)

  const { transaction } = props
  const { action, reason, createdAt } = transaction
  const { firstName, lastName, image } = transaction.authDevice.user

  const time = DateTime.fromISO(createdAt).toFormat("T") // createdAt.split('T')[1].split('.')[0];

  const icon =
    action === "clock-in" ? (
      <ArrowForwardIcon className={classes.arrowRight} />
    ) : (
      <ArrowBackIcon className={classes.arrowLeft} />
    )

  return (
    <Box key={transaction._id} className={classes.container}>
      <Avatar className={classes.avatar} src={image} />
      <ul className={classes.list}>
        <li className={classes.name}>
          {firstName} {lastName}
        </li>
        <li className={classes.action}>
          {icon} "{reason}"
        </li>
        <li className={classes.time}>{time}</li>
      </ul>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderWidth: `${1}px`,
    borderRadius: `${5}px`
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0
  },
  name: {
    color: theme.palette.text.primary
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    alignSelf: "center"
  },
  action: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic"
  },
  time: {
    color: theme.palette.text.primary
  },
  arrowLeft: {
    color: theme.palette.error.dark
  },
  arrowRight: {
    color: theme.palette.success.dark
  }
}))

export default TransactionItem