import React from "react"
import PropTypes from "prop-types"
import { Typography, Box } from "@material-ui/core"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      // style={{ height: `${100}%` }}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1
          }}
          p={3}
        >
          {children}
        </Box>
      )}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default TabPanel
