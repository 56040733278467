import React, { useState, useEffect } from "react"
import { DateTime } from "luxon"

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date())

  const timer = setTimeout(() => {
    setCurrentTime(new Date())
  }, 1000)

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  })

  return <div>{currentTime.toLocaleString(DateTime.DATETIME_MED)}</div>
}

export default Clock
