import * as dark from "./dark.json"
import * as light from "./light.json"
import * as monokai from "./monokai.json"
import * as nord from "./nord.json"
import * as pastelPink from "./pastelPink.json"

const themes = { dark, light, monokai, nord, pastelPink }

const getTheme = (theme = themes.light) => {
  return theme
}

export { themes, getTheme }
