import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { FormHelperText, Typography, Button, Box } from "@material-ui/core"
import DataDialog from "../common/DataDialog"

// const ADD_USER = gql`
//   mutation addUser($firstName: String!, $lastName: String!, $type: String!) {
//     addUser(firstName: $firstName, lastName: $lastName, type: $type) {
//       _id
//     }
//   }
// `

// const USER_TYPES = ["employee", "customer"]

const UserClockoutDialog = props => {
  const classes = useStyles()

  // const [values, setValues] = React.useState({
  //   loading: false,
  //   success: false,
  //   firstName: "",
  //   lastName: "",
  //   type: USER_TYPES[0],
  //   errorMessage: null
  // })

  // const [addUser, { data }] = useMutation(ADD_USER)

  const handleClockIn = () => { 
    props.onClose()
  }

  const handleClockOut = () => {
    props.onClose()
  }
  
  const handleClose = () => {
    props.onClose()
    // setValues({
    //   ...values,
    //   firstName: "",
    //   lastName: "",
    //   userType: USER_TYPES[0],
    //   success: false,
    //   loading: false,
    //   showDeviceToken: false,
    //   errorMessage: null
    // })
  }

  if (props.open) {
    return (
      <DataDialog
        open
        // title=""
        content={
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              aria-label="Clock-Out"
              onClick={() => handleClockIn()}
              className={classes.clockInButton}
              // disabled={state.loading}
              fullWidth
            >
              Clock In
            </Button>
            <Button
              variant="outlined"
              color="primary"
              aria-label="Clock-Out"
              onClick={() => handleClockOut()}
              className={classes.clockOutButton}
              // disabled={state.loading}
              fullWidth
            >
              Clock Out
            </Button>

          </Box>
        }
        actions={[]}
      />
    )
  }
  return null
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {},
  formContainer: {
    display: "flex",
    flexDirection: "column"
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {},
  listItem: {
    textTransform: "capitalize"
  },
  clockInButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  },
  clockOutButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  }
}))

export default UserClockoutDialog
