import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@material-ui/core";

interface Props {
  open: boolean
  title: string
  content: any
  actions: any
}

interface State {

}

class DataDialog extends React.Component<Props, State> {
  render() {
    const { open, title, content, actions } = this.props;
    return (
      <div className="Dialog">
        <Dialog open={open}>
          <DialogTitle>
            {title !== null ? title : ""}
          </DialogTitle>
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            {
              actions !== null &&
              actions.map((action: any, index: number) => {
                return <Button
                  key={index}
                  disabled={action.disabled}
                  color={action.primary ? "primary" : "default"}
                  onClick={() => action.onClick()}>
                  {!action.loading ? action.name : <CircularProgress />}
                </Button>
              })
            }
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const styles = (theme: any) => ({
  Dialog: {
    display: "flex"
  }
})

export default withStyles(styles)(DataDialog);