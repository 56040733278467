import React from "react"
import { DateTime } from "luxon"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import { CircularProgress, Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import TransactionDay from "../components/attendance/TransactionDay"
import CCTVDay from "../components/attendance/CCTVDay"

import { signOut } from "../utils/userHelper"

const TRANSACTIONS = gql`
  query cctvs(
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    cctvs(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      _id
      fileName
      thumbnailUrl
      videoUrl
      createdAt
    }
  }
`

const useStyles = makeStyles(theme => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    width: `${100}%`
  }
}))

const AttendanceCCTV = props => {
  const classes = useStyles(props)
  const { loading, error, data } = useQuery(TRANSACTIONS, {
    variables: {
      startDate: DateTime.local()
        .startOf("day")
        .minus({ days: 7 })
        .toISO(),
      endDate: DateTime.local()
        .endOf("day")
        .toISO(),
      limit: 9999
    }
  })

  if (loading) return <CircularProgress className={classes.progress} />
  if (error) {
    if (error.message.includes("token is invalid")) {
      signOut()
    }
    return `Error! ${error}`
  }

  const dayTransactions = data.cctvs.reduce(
    (transactions, transaction) => {
      const date = transaction.createdAt.split("T")[0]
      if (!transactions[date]) {
        transactions[date] = []
      }
      transactions[date].push(transaction)
      return transactions
    },
    {}
  )

  const dayTransactionArray = Object.keys(dayTransactions).map(date => {
    return {
      date,
      transactions: dayTransactions[date]
    }
  })

  console.log({dayTransactionArray})

  return (
    <Box>
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          7-day Review
        </Typography>
      </Box>
      {dayTransactionArray.map(day => {
        return <CCTVDay key={day.date} day={day} />
      })}
    </Box>
  )
}

export default AttendanceCCTV
