import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import thunkMiddleware from "redux-thunk"
import storage from "redux-persist/lib/storage"

import rootReducer from "./reducers"

const persistConfig = {
  key: "root",
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore)

export const store = createStoreWithMiddleware(persistedReducer)
export const persistor = persistStore(store)
