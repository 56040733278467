import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import DataDialog from "./common/DataDialog.tsx"

const UPDATE_USER = gql`
  mutation user(
    $_id: ID!
    $firstName: String
    $lastName: String
    $image: String
    $type: String
  ) {
    user(
      _id: $_id
      firstName: $firstName
      lastName: $lastName
      image: $image
      type: $type
    ) {
      _id
      firstName
      lastName
      image
      type
    }
  }
`

const DELETE_USER = gql`
  mutation deleteUser($_id: ID!) {
    deleteUser(_id: $_id) {
      _id
      deletedAt
    }
  }
`

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: `${520}px`
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    dingLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imageUploadOverlay: {
    position: "absolute",
    zIndex: 2,
    background: "rgba(0, 0, 0, 0.2)",
    color: "rgba(0,0,0,0.5)",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  avatar: {
    position: "absolute",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    minWidth: `${100}%`
  },
  halfWidth: {
    width: `${47.5}%`
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  },
  input: {
    display: "none"
  },
  submitButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  },
  deleteButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  },
  signoutButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  }
}))

const UserProfile = props => {
  console.log(props)
  const classes = useStyles(props)
  const [state, setState] = useState({
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    type: props.user.type,
    image: props.user.image,
    errorMessage: null,
    alert: false,
    loading: false,
    deleteDialogOpen: false
  })
  const [edit, setEdit] = useState(true)
  const inputLabel = React.useRef(null)

  const [user, { data, error, loading }] = useMutation(UPDATE_USER)
  const [
    deleteUser,
    { data: deleteUserData, error: deleteUserError, loading: deleteUserLoading }
  ] = useMutation(DELETE_USER)

  const handleChange = prop => event => {
    setState({ ...state, [prop]: event.target.value, errorMessage: null })
  }

  const handleSave = () => {
    if (!edit) {
      setEdit(!edit)
    } else if (edit) {
      setState({ ...state, loading: true })
      user({
        variables: {
          _id: props.user._id,
          firstName: state.firstName,
          lastName: state.lastName,
          type: state.type,
          image: state.image
        }
      })
        .then(res => {
          // setEdit(!edit);
          setState({
            ...state,
            loading: false,
            alert: true,
            message: "Profile updated"
          })
        })
        .catch(err => {
          let message = ""
          if (err.graphQLErrors.length > 0) {
            message = err.graphQLErrors[0].message
          } else {
            message = err.message
          }
          setState({
            ...state,
            errorMessage: message,
            alert: true,
            loading: false
          })
        })
    }
  }

  const handleDelete = () => {
    setState({ ...state, deleteDialogOpen: true })
  }

  const handleSignOut = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("refresh_token")
    window.location.replace("/")
  }

  const handleImageUpload = event => {
    console.log(event)
  }

  return (
    <Box className={classes.content}>
      {state.alert && (
        <Alert
          severity={state.errorMessage ? "error" : "success"}
          color={state.errorMessage ? "error" : "success"}
          className={classes.alert}
        >
          {state.errorMessage ? state.errorMessage : state.message}
        </Alert>
      )}
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          Details
        </Typography>
      </Box>
      <Box className={classes.profileDetails}>
        <form className={classes.formContainer} noValidate autoComplete="off">
          {/* <Box className={classes.imageContainer}>>
                  <img src={state.image} className={classes.avatar} title="" alt="" />
                  <Box className={classes.imageUploadOverlay}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id = "upload-image-file"
                        multiple
                        type="file"
                        onChange={(event) => handleImageUpload(event)}
                      />
                      <label htmlFor="upload-image-file">
                        <IconButton aria-label="upload picture" component="span">
                          <AddAPhotoIcon />
                        </IconButton>
                      </label>
                  </Box>
                </Box> */}
          <TextField
            required
            id="firstName"
            label="First name"
            value={state.firstName}
            onChange={handleChange("firstName")}
            // error={state.errorMessage && true}
            // helperText={state.errorMessage}
            variant="outlined"
            className={clsx(classes.formControl, classes.halfWidth)}
          />

          <TextField
            required
            id="lastName"
            label="Last name"
            value={state.lastName}
            onChange={handleChange("lastName")}
            variant="outlined"
            className={clsx(classes.formControl, classes.halfWidth)}
          />

          {!props.ownProfile && ( // user should not be able to change own type
            <FormControl
              required
              variant="outlined"
              className={clsx(classes.formControl, classes.fullWidth)}
            >
              <InputLabel ref={inputLabel} id="user-type-label">
                Type
              </InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type-select"
                value={state.type}
                onChange={handleChange("type")}
                labelWidth={50}
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="customer">Customer</MenuItem>
              </Select>
            </FormControl>
          )}

          <Button
            variant="contained"
            color="primary"
            aria-label="update profile"
            onClick={() => handleSave()}
            className={classes.submitButton}
            disabled={state.loading}
            fullWidth
          >
            Update Profile
          </Button>

          {!props.ownProfile && (
            <Button
              variant="outlined"
              color="secondary"
              aria-label="delete user account"
              onClick={() => handleDelete()}
              className={classes.deleteButton}
              disabled={state.loading}
              fullWidth
            >
              Delete User Account
            </Button>
          )}

          {props.ownProfile && (
            <Button
              variant="outlined"
              color="secondary"
              aria-label="Signout from user account"
              onClick={() => handleSignOut()}
              className={classes.signoutButton}
              disabled={state.loading}
              fullWidth
            >
              Logout
            </Button>
          )}

          <DataDialog
            open={state.deleteDialogOpen}
            title="Delete User"
            content="Are you sure you you want to delete this user?"
            actions={[
              {
                name: "Yes, Delete",
                loading: false,
                primary: false,
                onClick: () => {
                  setState({ ...state, loading: true })
                  deleteUser({
                    variables: {
                      _id: props.user._id
                    }
                  })
                    .then(res => {
                      // setEdit(!edit);
                      setState({
                        ...state,
                        loading: false,
                        alert: true,
                        message: "Account Deleted!",
                        deleteDialogOpen: false
                      })
                      window.location.replace("/users");
                    })
                    .catch(err => {
                      let message = ""
                      if (err.graphQLErrors.length > 0) {
                        message = err.graphQLErrors[0].message
                      } else {
                        message = err.message
                      }
                      setState({
                        ...state,
                        errorMessage: message,
                        alert: true,
                        loading: false,
                        deleteDialogOpen: false
                      })
                    })
                }
              },
              {
                name: "Cancel",
                loading: false,
                primary: true,
                onClick: () => setState({ ...state, deleteDialogOpen: false })
              }
            ]}
          />
        </form>
      </Box>
    </Box>
  )
}

export default UserProfile
