import React from "react"
import PropTypes from "prop-types"
import { withRouter, Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Toolbar, Typography, Breadcrumbs, Link } from "@material-ui/core"
import { Route } from "react-router"

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),

    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      marginLeft: theme.spacing(6)
    }
  }
}))

const breadcrumbNameMap = {
  "/user": "User",
  "/users": "Users",
  "/attendance": "Attendance"
}

const LinkRouter = props => <Link {...props} component={RouterLink} />

const PageHeader = props => {
  const classes = useStyles()
  const { title, pageName } = props

  return (
    <Toolbar className={classes.header}>
      <Typography variant="h5" component="h1">
        {title}
      </Typography>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split("/").filter(x => x)

          return (
            <Breadcrumbs aria-label="breadcrumb">
              <LinkRouter color="inherit" to="/">
                Home
              </LinkRouter>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join("/")}`

                return last ? (
                  <Typography color="textPrimary" key={to}>
                    {breadcrumbNameMap[to] || pageName}
                  </Typography>
                ) : (
                  <LinkRouter color="inherit" to={to} key={to}>
                    {breadcrumbNameMap[to]}
                  </LinkRouter>
                )
              })}
            </Breadcrumbs>
          )
        }}
      </Route>
    </Toolbar>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
}

const hasRouterPage = withRouter(PageHeader)
export default hasRouterPage
