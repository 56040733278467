import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Box, Typography } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import ChangePassword from "./ChangePassword"

const useStyles = makeStyles(theme => ({
  content: {},
  alert: {
    marginBottom: theme.spacing(2)
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: `${520}px`
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    dingLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imageUploadOverlay: {
    position: "absolute",
    zIndex: 2,
    background: "rgba(0, 0, 0, 0.2)",
    color: "rgba(0,0,0,0.5)",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  avatar: {
    position: "absolute",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    minWidth: `${100}%`
  },
  halfWidth: {
    width: `${47.5}%`
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  },
  input: {
    display: "none"
  },
  submitButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  }
}))

const UserAuthentication = props => {
  const classes = useStyles(props)
  const [state, setState] = useState({
    errorMessage: null,
    alert: false,
    loading: false
  })

  return (
    <Box className={classes.content}>
      {state.alert && (
        <Alert
          severity={state.errorMessage ? "error" : "success"}
          color={state.errorMessage ? "error" : "success"}
          className={classes.alert}
        >
          {state.errorMessage ? state.errorMessage : state.message}
        </Alert>
      )}
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          Change Password
        </Typography>
      </Box>
      <Box className={classes.profileDetails}>
        <ChangePassword />
      </Box>
    </Box>
  )
}

export default UserAuthentication
