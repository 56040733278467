import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import DataDialog from './common/DataDialog';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { FormHelperText, Typography } from '@material-ui/core';

const ADD_USER = gql`
  mutation addUser($firstName: String!, $lastName: String!, $type: String!) {
    addUser(firstName: $firstName, lastName: $lastName, type: $type) {
      _id
    }
  }
`;

const USER_TYPES = [
  'employee',
  'customer'
]

const AddUserDialog = (props) => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    loading: false,
    success: false,
    firstName: '',
    lastName: '',
    type: USER_TYPES[0],
    errorMessage: null
  });

  const [addUser, { data }] = useMutation(ADD_USER);

  const handleClose = () => {
    props.onClose();
    setValues({
      ...values,
      firstName: '',
      lastName: '',
      userType: USER_TYPES[0],
      success: false,
      loading: false,
      showDeviceToken: false,
      errorMessage: null
    });
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorMessage: null });
  };

  const handleAddUser = () => {
    setValues({ ...values, loading: true });
    addUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        type: values.type,
      }
    }).then(res => {
      console.log('data', res.data);
      setValues({
        ...values,
        firstName: '',
        lastName: '',
        type: USER_TYPES[0],
        errorMessage: null,
        loading: false,
        success: true
      });
    }).catch(err => {
      console.error({err});
      let message = "";
      if (err.graphQLErrors.length > 0) {
        message = err.graphQLErrors[0].message;
      } else {
        message = err.message;
      }
      setValues({
        ...values,
        errorMessage: message,
        loading: false
      });
    });
  }

  if (props.open) {
    return (
      <DataDialog 
        open={true}
        title={'Add User'} 
        content={
          !values.success ?
          <div>
            {/* <Typography>Enter new user details...</Typography> */}
            <form className={classes.formContainer}>
              <FormControl error={values.errorMessage ? true : false} className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-device">First Name</InputLabel>
                <Input
                  id="firstName"
                  type={'text'}
                  value={values.firstName}
                  onChange={handleChange('firstName')}
                  autoComplete="false"
                  fullWidth
                />
                <FormHelperText id="firstName-helper-text">{values.errorMessage}</FormHelperText>
              </FormControl>
              <FormControl error={values.errorMessage ? true : false} className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-device">Last Name</InputLabel>
                <Input
                  id="lastName"
                  type={'text'}
                  value={values.lastName}
                  onChange={handleChange('lastName')}
                  autoComplete="false"
                  fullWidth
                />
                <FormHelperText id="lastName-helper-text">{values.errorMessage}</FormHelperText>
              </FormControl>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel id="user-type-select-label">Type</InputLabel>
                <Select
                  labelId="user-type-select-label"
                  id="user-type-select"
                  value={values.deviceType}
                  fullWidth
                  onChange={(event) => setValues({ ...values, userType: event.target.value })}
                >
                  { 
                    USER_TYPES.map((type, index) => {
                      return <MenuItem key={index} value={type} className={classes.listItem}>{type}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </form>
            
          </div>
          :
          <Typography>User added successfully :-)</Typography>
          
        } 
        actions={[
          {
            name: "Save",
            disabled: values.success,
            primary: true,
            loading: values.loading,
            onClick: () => handleAddUser()
          },
          { 
            name: !values.success ? "Cancel" : "Close", 
            primary: false, 
            onClick: () => handleClose()},
        ]} />
    )
  } else {
    return null;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {},
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
  listItem: {
    textTransform: "capitalize"
  }
}));

export default AddUserDialog;