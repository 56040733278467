import React, { useEffect } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { withRouter } from "react-router-dom"

import gql from "graphql-tag"
import { Query } from "react-apollo"

import Helmet from "react-helmet"
import {
  Box,
  Tab,
  Tabs,
  Divider,
  Typography,
  Avatar,
  Container
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { DateTime } from "luxon";

import SwipeableViews from "react-swipeable-views"
import TabPanel from "../components/common/TabPanel"
import MiniDrawer from "../components/common/MiniDrawer"
import PageHeader from "../components/common/PageHeader"

import { APP_NAME } from "../constants"

import { hasRole, signOut } from "../utils/userHelper"
import DoorReleaseButton from "../components/DoorReleaseButton"
import ClockedHoursChart from "../components/attendance/ClockedHoursChart"

const DASHBOARD_QUERY = gql`
  query {
    loggedInUser {
      _id
      firstName
      lastName
      type
      clockedIn
    }
    users {
      _id
      firstName
      lastName
      clockedIn
      image
      type
    }
  }
`

const tabs = ["Metrics", "Macros"]

const Dashboard = props => {
  const classes = useStyles()
  const { authed, history, user } = props

  const startDate = DateTime.local().startOf("month").toFormat("dd")
  const currentDate = DateTime.local().toFormat("DD")

  const mobileView = useMediaQuery("(max-width:599px)")

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#metrics":
        return 0
      case "#macros":
        return 1
    }
  })

  const handleTabChange = (event, newTab) => {
    history.push(`#${tabs[newTab].toLocaleLowerCase()}`)
    setCurrentTab(newTab)
  }

  const handleChangeTabIndex = index => {
    setCurrentTab(index)
  }

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-6" />
        <title>{`${APP_NAME} | Dashboard`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader title="Dashboard" pageName="" />

      <Box className={classes.tabContainer}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />
          })}
        </Tabs>
        <Divider className={classes.divider} />
      </Box>

      <Box className={classes.content}>
        <SwipeableViews
          index={currentTab}
          onChangeIndex={handleChangeTabIndex}
          animateTransitions={mobileView}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel className={classes.tabPanel} value={currentTab} index={0}>
            <Query query={DASHBOARD_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return null
                if (error) {
                  if (error.message.includes("token is invalid")) {
                    signOut()
                  }
                  return `Error! ${error}`
                }

                localStorage.setItem(
                  "currentUser",
                  JSON.stringify(data.loggedInUser)
                )

                return (
                  <Box>
                    <Box className={classes.clockedInContainer}>
                      {/* <Typography component="h3" className={classes.greyTitle}> */}
                      <Typography variant="h5" component="h1">
                        Clocked-in Now
                      </Typography>
                      <ul style={{ listStyle: "none", padding: 0 }}>
                        {data.users
                          .filter(user => user.clockedIn)
                          .map(user => (
                            <li key={user._id} className={classes.listItem}>
                              <Avatar
                                className={classes.avatar}
                                src={user.image}
                              >
                                {`${user.firstName[0]}`}
                              </Avatar>
                              {`${user.firstName} ${user.lastName}`}
                            </li>
                          ))}
                      </ul>
                    </Box>
                    <Box>
                      {/* <Typography component="h3" className={classes.greyTitle}> */}
                      <Typography variant="h5" component="h1">
                        Clocked-in hours during {`${DateTime.local().monthShort}`}
                      </Typography>
                      <ClockedHoursChart />
                    </Box>
                  </Box>
                )
              }}
            </Query>
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={1}>
            <DoorReleaseButton className={classes.doorReleaseButton} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(3)
    }
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0)
    }
  },
  tabs: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    minWidth: "inherit",
    marginRight: `${28}px`
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      boxShadow: "unset"
    }
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  greyTitle: {
    textTransform: "uppercase",
    color: theme.palette.secondary
  },
  doorReleaseButton: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  clockedInContainer: {
    marginBottom: theme.spacing(8)
  }
}))

const hasRouterPage = withRouter(Dashboard)
export default hasRouterPage
