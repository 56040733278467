import React, { useState, useEffect } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/core/styles"

import Helmet from "react-helmet"

import {
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  InputAdornment
} from "@material-ui/core"

import MailOutlineIcon from "@material-ui/icons/MailOutline"
import LockOpenIcon from "@material-ui/icons/LockOpen"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const LOGIN_USER = gql`
  mutation Login($email: String, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

const Login = props => {
  const classes = useStyles(props)
  const [state, setState] = useState({
    loading: false,
    validEmailAddress: false,
    error: false,
    emailAddress: "",
    password: "",
    helperText: ""
  })
  const { validEmailAddress } = state
  const mobileView = useMediaQuery("(max-width:599px)")

  useEffect(() => {
    // If logged in then reroute browser
    if (window.localStorage.getItem("token")) {
      window.location.replace("/")
    }
  }, [])

  const [login] = useMutation(LOGIN_USER)

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value, error: false })
  }

  const handleLoginResponse = res => {
    const { data } = res
    if (data.hasOwnProperty("login")) {
      localStorage.setItem("token", data.login.accessToken)
      localStorage.setItem("refresh_token", data.login.refreshToken)
      window.location.replace("/")
    }
    setState({
      ...state,
      loading: false,
      error: false,
      helperText: ""
    })
  }

  const handleLoginError = error => {
    console.error(error.message)
    setState({
      ...state,
      loading: false,
      error: true
    })
  }

  const handleAuthWithEmailAddress = () => {
    const { emailAddress, password } = state
    setState({ ...state, loading: true })

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (emailAddress.match(emailRegex)) {
      login({ variables: { email: emailAddress, password } })
        .then(res => {
          handleLoginResponse(res)
        })
        .catch(error => {
          handleLoginError(error)
        })
    } else {
      setState({
        ...state,
        loading: false,
        error: true,
        helperText: "Invalid Email Address!"
      })
    }
  }

  const handleForgottenPassword = () => {
    alert("Hard cheese!")
  }

  const renderLoginForm = () => {
    const { loading, error, emailAddress, password } = state

    return (
      <form
        className={classes.formContainer}
        id="emailAdd ressForm"
        autoComplete="off"
      >
        <Typography
          className={classes.titleMessage}
          component="h4"
          variant="h4"
        >
          Welcome Back
        </Typography>

        <TextField
          id="emailAddress"
          placeholder="Email Address"
          variant="outlined"
          className={classes.textField}
          value={emailAddress}
          onKeyPress={event => {
            if (event.charCode === 13) {
              event.preventDefault()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
          onChange={handleChange("emailAddress")}
          margin="normal"
          pattern="/^[^\s@]+@[^\s@]+\.[^\s@]+$/"
          type="email"
          error={error}
          helperText={error ? "Enter a valid email address!" : ""}
          fullWidth
        />
        <TextField
          id="password"
          placeholder="Password"
          variant="outlined"
          className={classes.textField}
          value={password}
          onKeyPress={event => {
            if (event.charCode === 13) {
              event.preventDefault()
              handleAuthWithEmailAddress()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOpenIcon />
              </InputAdornment>
            )
          }}
          onChange={handleChange("password")}
          margin="normal"
          type="password"
          error={error}
          helperText={error ? "Invalid password" : ""}
          fullWidth
        />
        <Button
          onClick={() => handleForgottenPassword()}
          className={classes.forgottenPasswordButton}
        >
          Forgotten Password?
        </Button>

        <Button
          className={classes.submitButton}
          id="sign-in-button"
          disabled={loading}
          onClick={() => handleAuthWithEmailAddress()}
          variant="contained"
          color="primary"
          aria-label="login"
          fullWidth
        >
          {loading ? (
            <CircularProgress size={18} className={classes.buttonProgress} />
          ) : (
            <Typography>Log In</Typography>
          )}
        </Button>
      </form>
    )
  }

  const renderCheckEmailMessage = () => {
    const { emailAddress } = state
    return (
      <Box style={{ textAlign: "center", marginTop: 14 }}>
        <Typography variant="body1">
          A confirmation email has been sent to <strong>{emailAddress}</strong>,
          please check your email and click the sign-in link.
        </Typography>
      </Box>
    )
  }

  const renderBackground = () => {
    if (!mobileView) {
      return (
        <Box className={classes.background}>
          <svg
            className={classes.backgroundSvg}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1903 556"
          >
            <path
              className={classes.backgroundSvgShape}
              d="M753.1,434.2c110.6,63.7,277.7,70.6,373.4,15.4L1905,0v555.9H0V0.2L753.1,434.2z"
            />
          </svg>
        </Box>
      )
    }
    return null
  }

  return (
    <Box className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FrontDesk | Login</title>
      </Helmet>

      {renderBackground(classes, mobileView)}
      <Box className={classes.brand}>
        <img
          src="/assets/images/logo-white.png"
          width="200"
          alt="Study Buddy"
        />
      </Box>

      <Paper elevation={0} className={classes.paper}>
        {!validEmailAddress ? renderLoginForm() : renderCheckEmailMessage()}
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  brand: {
    minHeight: `${120}px`
  },
  background: {
    position: "absolute",
    top: 0,
    left: `${50}%`,
    zIndex: -1,
    width: `${100}%`,
    height: `${70}%`, // `${100}%`,
    minWidth: `${1920}px`,
    userSelect: "none",
    transform: "translateX(-50%)",
    background: `linear-gradient(90deg,${theme.palette.primary.gradientStart},${theme.palette.primary.gradientEnd})`
  },
  backgroundSvg: {
    position: "absolute",
    bottom: `${-1}px`,
    left: `${-1}px`,
    right: `${-1}px`
  },
  backgroundSvgShape: {
    fill: theme.palette.background.default
  },
  paper: {
    minWidth: `${480}px`,
    minHeight: `${356}px`,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      boxShadow: "unset"
    }
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-end"
    padding: `${36}px ${56}px ${48}px ${56}px`
  },
  titleMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3)
  },
  formColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // marginTop: 0,
    // width: 200
  },
  button: {
    width: 26,
    height: 26,
    minHeight: 26,
    boxShadow: "none",
    marginBottom: 8
  },
  submitButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9,
    marginLeft: -9
  },
  forgottenPasswordButton: {
    textTransform: "none"
  },
  centerAlign: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}))

export default Login
