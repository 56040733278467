import React, { useEffect } from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { HorizontalBar } from "react-chartjs-2"

import { DateTime, Duration } from "luxon"

import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import * as transactions from "../../helpers/userTransactions"

const TRANSACTIONS = gql`
  query cardTransactions(
    $filterByTokens: [String]
    $includeAuthDevice: Boolean
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    cardTransactions(
      filterByTokens: $filterByTokens
      includeAuthDevice: $includeAuthDevice
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      _id
      token
      action
      reason
      authDevice {
        user {
          _id
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {}
}))

const ClockedHoursChart = props => {
  const classes = useStyles(props)

  const [state, setState] = React.useState({
    maxSize: 100,
    names: [],
    hours: []
  })

  const { loading, error, data } = useQuery(TRANSACTIONS, {
    variables: {
      includeAuthDevice: true,
      startDate: DateTime.local()
        .startOf("month")
        .toISO(),
      endDate: DateTime.local()
        .endOf("day")
        .minus({ days: 1 })
        .toISO(),
      limit: 9999
    }
  })

  const formatTransactions = async cardTransactions => {
    const results = await transactions
      .orderByDateAndUser(cardTransactions)
      .then(res => transactions.calculateElapsedTime(res))
      .then(res => transactions.totalTimeByUserId(res))
      .catch(err => {
        console.error(err)
        return []
      })
    console.log("clockedHoursChart", results)

    const names = []
    const hours = []

    results.forEach(user => {
      names.push(user.name)
      hours.push(
        Duration.fromObject({
          hours: user.hours.split(":")[0],
          minutes: user.hours.split(":")[1],
          seconds: 0
        }).toFormat("hh")
      )
    })

    console.log("userTransactions", { names, hours })

    setState({
      ...state,
      names,
      hours,
      maxSize: Math.max(...hours)
    })
  }

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (data !== undefined) {
      formatTransactions(data.cardTransactions)
    }
  }, [data])

  const options = {
    legend: false,
    scales: {
      xAxes: [
        {
          id: "topX",
          display: true,
          position: "top",
          type: "linear",
          ticks: {
            beginAtZero: true,
            precision: 1,
            stepSize: 10,
            min: 0,
            max: state.maxSize
          }
        },
        {
          id: "bottomX",
          display: true,
          position: "bottom",
          type: "linear",
          ticks: {
            beginAtZero: true,
            precision: 1,
            stepSize: 10,
            min: 0,
            max: state.maxSize
          }
        }
      ]
    }
  }

  const chartData = {
    labels: state.names,
    datasets: [
      {
        label: "Clocked-in hours this month",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: state.hours
      }
    ]
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error...</div>

  return (
    <Box>
      <HorizontalBar data={chartData} options={options} />
    </Box>
  )
}

export default ClockedHoursChart
