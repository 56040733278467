export function hasRole(role, user) {
  if (user !== null) {
    if (user.hasOwnProperty("roles")) {
      if (user.roles.length > 0) {
        return user.roles.includes(role)
      }
      return false
    }
    return false
  }
}

export function signOut() {
  localStorage.removeItem("token")
  localStorage.removeItem("refresh_token")
  window.location.replace("/")
}
