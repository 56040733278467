import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Button } from "@material-ui/core"

import { useSocket } from "use-socketio"

import { settings } from "../constants"

const useStyles = makeStyles(theme => ({
  buttonDoorRelease: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: `${1.5}em`
    // minHeight: `${60}px`
  }
}))

const DoorReleaseButton = props => {
  const classes = useStyles(props)
  const [processingDoorRelease, setProcessingDoorRelease] = useState(false)

  const socketDoorReleaseStatus = useSocket("doorReleaseStatus", status => {
    if (status.status === "OFF") setProcessingDoorRelease(false)
    console.debug("[socket.io][received][doorReleaseStatus]", status)
  })

  const handleButtonPress = () => {
    setProcessingDoorRelease(true)
    socketDoorReleaseStatus.emit("doorReleaseAction", {
      doorName: settings.door_release.name,
      action: "ON"
    })
  }

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={processingDoorRelease}
      className={clsx(classes.buttonDoorRelease, props.className)}
      onClick={() => handleButtonPress()}
      fullWidth
    >
      {!processingDoorRelease ? "Door Release" : "Pull Door Handle!"}
    </Button>
  )
}

export default DoorReleaseButton
