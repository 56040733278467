import React from "react"
import ReactDOM from "react-dom"

import { Settings } from "luxon"

import ApolloClient from "apollo-boost"
import { ApolloProvider } from "@apollo/react-hooks"
import { ClientSocket } from "use-socketio"

import {
  MuiThemeProvider,
  CssBaseline,
  createTheme
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import * as serviceWorker from "./serviceWorker"

import App from "./App"
import "./index.css"
import { system, settings } from "./constants"
// import user from "./redux/reducers/user"
import { themes, getTheme } from "./themes"

const { graphql, socketio } = system[settings.release]

const clientGraphQL = new ApolloClient({
  uri: `${graphql.protocol}//${graphql.address}:${graphql.port}/graphql`,
  request: operation => {
    const token = localStorage.getItem("token")
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    })
  }
})

const clientSocketIO = `${socketio.protocol}//${socketio.address}:${socketio.port}`
const socketIoOptions = {
  properties: {
    receiveMaximum: 1
  }
}

/**
 * Description:
 * Check browser's local storage for user preferences, if they don't exist
 * then create them and reload
 */
const loadUserPreferences = () => {
  let userPreferences = localStorage.getItem("userPreferences")
  if (userPreferences) {
    userPreferences = JSON.parse(userPreferences)
    Settings.defaultZoneName = userPreferences.timeZone // "Asia/Hong_Kong"
  } else {
    userPreferences = {
      timeZone: "Asia/Hong_Kong",
      theme: "default"
    }
    localStorage.setItem("userPreferences", JSON.stringify(userPreferences))
    loadUserPreferences() // load newly saved preferences
  }
  return userPreferences
}

const Root = () => {
  const userPreferences = loadUserPreferences()

  const devicePreferredTheme = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light"

  const theme =
    userPreferences.theme === "default"
      ? createTheme(getTheme(themes[devicePreferredTheme]).default)
      : createTheme(getTheme(themes[userPreferences.theme]).default)

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, 
            consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ApolloProvider client={clientGraphQL}>
          <ClientSocket url={clientSocketIO} opts={socketIoOptions}>
            <App />
          </ClientSocket>
        </ApolloProvider>
      </MuiThemeProvider>
    </Provider>
  )
}

ReactDOM.render(<Root />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
