export const APP_NAME = "frontDESK"
export const COMPANY_NAME = "Study Buddy"
export const WELCOME_LOGO = "/assets/images/logo-red.png"
export const WELCOME_TEXT = "Please swipe your card/keyfob to clock-in/out.."

export const settings = {
  app_name: "frontDESK",
  company_name: "Study Buddy",
  release: process.env.NODE_ENV,
  welcome_logo: "/assets/images/logo-red.png",
  welcome_text:
    "Welcome to Study Buddy! <br />Please swipe your card/keyfob to clock-in/out.",
  punch_card: {
    reasons: {
      employee: {
        in: [
          "ready to work",
          "external lesson",
          // 'back from the shops',
          "lunch break",
          "comfort break"
          // 'cancel'
        ],
        out: [
          "off home",
          "external lesson",
          // 'off to the shops',
          "lunch break",
          "comfort break"
          // 'cancel'
        ]
      },
      customer: {
        in: [
          "ready to work",
          "comfort break"
          // 'cancel'
        ],
        out: [
          "off home",
          "comfort break"
          // 'cancel'
        ]
      }
    }
  },
  door_release: {
    name: "front_door/POWER1"
  }
}

export const system = {
  production: {
    graphql: {
      protocol: window.location.protocol,
      address: window.location.hostname,
      port: window.location.port
    },
    socketio: {
      protocol: window.location.protocol,
      address: window.location.hostname,
      port: window.location.port
    }
  },
  development: {
    graphql: {
      protocol: "http:",
      address: "localhost",
      port: 3001
    },
    socketio: {
      protocol: "http:",
      address: "localhost",
      port: 3001
    }
  }
}

// export const GRAPHQL_SERVER_PROTOCOL = window.location.protocol;
// export const GRAPHQL_SERVER_ADDR = window.location.hostname;
// export const GRAPHQL_SERVER_PORT = 8888//window.location.port;

// export const SOCKETIO_SERVER_PROTOCOL = window.location.protocol;
// export const SOCKETIO_SERVER_ADDR = window.location.hostname;
// export const SOCKETIO_SERVER_PORT = 8888//window.location.port;
