import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import { useSocket } from "use-socketio"

import Helmet from "react-helmet"
import { Box, Button, Typography, IconButton } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import Clock from "../components/common/Clock"
// import CountdownTimer from "../components/common/CountdownTimer.tsx"

import {
  APP_NAME,
  COMPANY_NAME,
  WELCOME_LOGO,
  WELCOME_TEXT,
  settings
} from "../constants"

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: `${100}vh`
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2)
  },
  countdownTimer: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.secondary
  },
  userAvatar: {
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`
  },
  userDetails: {
    marginLeft: `${16}px`
  },
  actionButtons: {
    display: "flex",
    flex: 1
  },
  button: {
    flex: 1,
    fontSize: `${2}em`,
    margin: theme.spacing(1)
  },
  buttonDoorRelease: {
    fontSize: `${1.5}em`,
    minHeight: `${60}px`
  },
  welcomeContainer: {
    alignSelf: "center"
  },
  header: {
    flex: 1,
    width: `${100}%`
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 100,
    width: `${100}%`,
    height: `${100}%`,
    padding: theme.spacing(1)
  },
  footer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: `${100}%`,
    height: `${28}px`,
    padding: `${4}px`
  },
  clock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: `${4}px`,
    marginRight: `${4}px`
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  statusIndicator: {
    marginLeft: `${4}px`,
    marginRight: `${4}px`,
    height: `${12}px`,
    width: `${12}px`,
    borderRadius: `${6}px`
  },
  statusConnected: {
    backgroundColor: "green"
  },
  statusDisconnected: {
    backgroundColor: "red"
  }
}))

const PunchCard = props => {
  const classes = useStyles(props)

  const [activeToken, setActiveToken] = useState(null)
  const [activeDevice, setActiveDevice] = useState(null)
  const [connectedStatus, setConnectedStatus] = useState(false)
  const [clockAction, setClockAction] = useState(null)
  const [clockReason, setClockReason] = useState(null)
  const [processingDoorRelease, setProcessingDoorRelease] = useState(false)

  /**
   * Setup Socket.IO
   */
  const socketCardScanned = useSocket("nfc-card-scanned", uid => {
    setActiveToken(uid)
    socketCardScanned.emit("authDeviceByTokenRequest", uid)
    console.debug("[socket.io][sent][authDevice]", { token: uid })
  })

  const socketConnect = useSocket("connect", () => {
    console.debug("[socket.io][received][connection]", "connected!")
    setConnectedStatus(true)
  })

  const socketDisconnect = useSocket("disconnect", () => {
    console.debug("[socket.io][received][connection]", "disconnected!")
    setConnectedStatus(false)
  })

  const socketAuthDeviceByTokenResponse = useSocket(
    "authDeviceByTokenResponse",
    authDevice => {
      setActiveDevice(authDevice)
      console.debug("[socket.io][received][authDevice]", authDevice)
    }
  )

  const socketAuthDeviceByTokeResponse = useSocket(
    "authDeviceByTokenRequest",
    uid => {
      setActiveToken(uid)
      socketAuthDeviceByTokeResponse.emit("authDeviceByTokenRequest", uid)
      console.debug("[socket.io][sent][authDevice]", { token: uid })
    }
  )

  const socketDoorReleaseStatus = useSocket("doorReleaseStatus", status => {
    if (status.status === "OFF") setProcessingDoorRelease(false)
    console.debug("[socket.io][received][doorReleaseStatus]", status)
  })

  const socketPunchCard = useSocket("punchcard", status => {
    // if (status.status === "OFF") setProcessingDoorRelease(false)
    console.debug("[socket.io][received][punchcard]", status)
  })

  /**
   * Welcome Screen
   */
  const renderWelcomeScreen = () => {
    return (
      <Box className={classes.body}>
        <Box className={classes.welcomeContainer}>
          <img src={WELCOME_LOGO} title={COMPANY_NAME} alt={COMPANY_NAME} />
          <Typography paragraph align="center">
            {WELCOME_TEXT}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            disabled={processingDoorRelease}
            className={classes.buttonDoorRelease}
            fullWidth
          >
            {!processingDoorRelease ? "Door Release" : "Pull Door Handle!"}
          </Button>
        </Box>
      </Box>
    )
  }

  /**
   * Call to Action Screen
   */
  const renderCurrentUser = () => {
    const handleClockAction = (action, reason) => {
      socketPunchCard.emit("punchcard", {
        token: activeToken,
        action,
        reason
      })

      setActiveToken(null)
      setActiveDevice(null)
      setClockAction(null)
      setClockReason(null)
    }

    const renderButtons = (action, reason) => {
      // render in/out
      if (!action) {
        return (
          <Box className={classes.actionButtons}>
            <Button
              color="primary"
              disabled={activeDevice.user.clockedIn}
              variant={!activeDevice.user.clockedIn ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setClockAction("clock-in")}
              fullWidth
            >
              In
            </Button>
            <Button
              color="primary"
              disabled={!activeDevice.user.clockedIn}
              variant={activeDevice.user.clockedIn ? "contained" : "outlined"}
              className={classes.button}
              onClick={() => setClockAction("clock-out")}
              fullWidth
            >
              Out
            </Button>
          </Box>
        )
      }

      // render reason
      if (!reason) {
        const { type: userType } = activeDevice.user

        const reasons =
          clockAction === "clock-in"
            ? settings.punch_card.reasons[userType].in
            : settings.punch_card.reasons[userType].out

        return (
          <Box className={classes.actionButtons}>
            {reasons.map((reason, index) => {
              return (
                <Button
                  key={index}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    setClockReason(reason)
                  }}
                  fullWidth
                >
                  {reason}
                </Button>
              )
            })}
          </Box>
        )
      }

      handleClockAction(clockAction, clockReason)
    }

    if (activeToken && activeDevice) {
      let image = "/assets/images/default-avatar.png"
      if (activeDevice.user) {
        image = activeDevice.user.image
      }
      return (
        <Box className={classes.body}>
          {/* <CountdownTimer
            value={10}
            className={classes.countdownTimer}
            onComplete={() => {
              setActiveToken(null)
              setActiveDevice(null)
              setClockAction(null)
              setClockReason(null)
            }}
          /> */}
          {!clockAction && (
            <Box className={classes.userContainer}>
              <Box>
                <img
                  src={image}
                  className={classes.userAvatar}
                  title=""
                  alt=""
                />
              </Box>
              <Box className={classes.userDetails}>
                <Typography>
                  Name: {activeDevice.user.firstName}{" "}
                  {activeDevice.user.lastName}
                </Typography>
                <Typography>Type: {activeDevice.type} </Typography>
                <Typography>
                  Status:{" "}
                  {activeDevice.user.clockedIn ? "clocked-in" : "clocked-out"}{" "}
                </Typography>
              </Box>
            </Box>
          )}

          {renderButtons(clockAction, clockReason)}
        </Box>
      )
    }
    return null
  }

  const renderHeader = () => {
    if (activeToken && activeDevice) {
      return (
        <Box className={classes.header}>
          <IconButton
            aria-label="back"
            onClick={() => {
              if (clockAction) {
                setClockAction(null)
                setClockReason(null)
              } else {
                setActiveDevice(null)
                setActiveToken(null)
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
      )
    }
    return null
  }

  const renderFooter = () => {
    return (
      <Box className={classes.footer}>
        <Box className={classes.statusContainer}>
          <Box
            className={`${classes.statusIndicator} ${
              connectedStatus
                ? classes.statusConnected
                : classes.statusDisconnected
            }`}
          />
          {connectedStatus ? "connected" : "disconnected"}
        </Box>
        <Box className={classes.clock}>
          <Clock />
        </Box>
      </Box>
    )
  }

  const handleFullscreenButton = () => {
    if (!activeToken && !activeDevice && !processingDoorRelease) {
      setProcessingDoorRelease(true)
      socketDoorReleaseStatus.emit("doorReleaseAction", {
        doorName: settings.door_release.name,
        action: "ON"
      })
    }
  }

  return (
    <Box className="dashboard">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | Time & Attendance`}</title>
      </Helmet>

      <Box className={classes.content} onClick={() => handleFullscreenButton()}>
        {renderHeader()}

        {(!activeToken || !activeDevice) && renderWelcomeScreen()}
        {activeToken && activeDevice && renderCurrentUser()}

        {renderFooter()}
      </Box>
    </Box>
  )
}

const hasRouterPage = withRouter(PunchCard)
export default hasRouterPage
