import React from 'react';
import OneSignal, {useOneSignalSetup} from 'react-onesignal';

const options = {}

export function PushNotifications({authed}) {

  OneSignal.initialize('a0303b58-de9d-496e-97cc-0b641aa53ec8', options);

  useOneSignalSetup( async () => {
    try {
      // only register if for notificaitons if the user is authenticated.
      if (authed) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'))['_id']
        // OneSignal.setEmail(user.email);
        OneSignal.setExternalUserId(currentUser);

        // Check all possible permission states
        const permissions = OneSignal.notificationPermission;
    
        // Check current permission state
        const currentState = await OneSignal.getNotificationPermission();
    
        // Ask the user for notification permissions, if not granted yet
        await OneSignal.registerForPushNotifications();
    
        const playerId = await OneSignal.getPlayerId();

        console.log({playerId, permissions, currentState})
      }
    } catch (err) {
      console.log("registerOneSignal:", err)
    }
  });

  return (<></>)
}

export default PushNotifications;