import React, { useState } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Helmet from "react-helmet"
import {
  Avatar,
  Box,
  TableContainer,
  Tabs,
  Tab,
  Divider,
  Fab,
  Container
} from "@material-ui/core"

import AddIcon from "@material-ui/icons/Add"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import MiniDrawer from "../components/common/MiniDrawer"
import PageHeader from "../components/common/PageHeader"

import DataTable from "../components/common/DataTable.tsx"
import ContextMenu from "../components/common/ContextMenu.tsx"
import AddUserDialog from "../components/AddUserDialog"
import UserListItem from "../components/users/UserListItem"

import { signOut } from "../utils/userHelper"

import { APP_NAME } from "../constants"
import TabPanel from "../components/common/TabPanel"
import SwipeableViews from "react-swipeable-views"
import UserClockoutDialog from "../components/attendance/UserClockoutDialog"

const GET_USERS = gql`
  {
    users {
      _id
      firstName
      lastName
      clockedIn
      image
      type
    }
  }
`

const tabs = ["All", "Employees", "Customers"]

const UsersView = props => {
  const classes = useStyles(props)
  const { authed, history, user } = props

  const mobileView = useMediaQuery("(max-width:599px)")

  const [openAddUser, setOpenAddUser] = useState(false)
  const [openUserClockout, setOpenUserClockout] = useState(false)

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#all":
        return 0
      case "#employees":
        return 1
      case "#customers":
        return 2
    }
  })

  const handleTabChange = (event, newTab) => {
    history.push(`#${tabs[newTab].toLocaleLowerCase()}`)
    setCurrentTab(newTab)
  }

  const handleChangeTabIndex = index => {
    setCurrentTab(index)
  }

  // const handleTabChange = (event, newTab) => {
  //   console.log(newTab)
  //   setCurrentTab(newTab)
  // }

  const USER_TYPES = ["*", "employee", "customer"]

  const { loading, error, data } = useQuery(GET_USERS)

  if (error) {
    if (error.message.includes("token is invalid")) {
      signOut()
    }
    return `Error! ${error}`
  }

  const renderUsersList = () => {
    let filteredData = []
    if (data) {
      filteredData =
        currentTab === 0
          ? data.users
          : data.users.filter(user => user.type === USER_TYPES[currentTab])

      filteredData.sort((a, b) => {
        const textA = a.firstName.toUpperCase()
        const textB = b.firstName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    }

    return (
      <Box className={classes.userList}>
        {/* {loading && <p>Loading...</p>}
        {error && `${error}`} */}
        {filteredData.map((user, index) => {
          return (
            <UserListItem
              key={user._id}
              user={user}
              // onClick={user => history.push(`/users/${user._id}`)}
              onClick={() => history.push(`/users/${user._id}`)}
              onLongPress={() => setOpenUserClockout(true)}

              //  open={openAddUser} onClose={() => setOpenAddUser(false)}
              // onClick={() => console.log(user._id)}
            />
          )
        })}
      </Box>
    )
  }

  const renderUsersTable = () => {
    return (
      <TableContainer className={classes.users}>
        {loading && <p>Loading...</p>}
        {error && `${error}`}
        {data && (
          <DataTable
            // pagination={true}
            // search={true}
            // paginationIntervals={[5, 10, 20, 50, 100]}
            rowHoverHightlight
            filterColumn="firstName"
            searchQuery=""
            // headers={["#", "First Name", "Last Name", "Status"]}
            headers={["First Name", "Last Name", "Status"]}
            columns={[
              // {
              //   type: "index"
              // },
              "firstName",
              "lastName",
              {
                type: "boolean",
                value: "clockedIn",
                valueTrue: "clocked-in",
                valueFalse: "clocked-out"
              }
            ]}
            rowActions={row => {
              return (
                <ContextMenu
                  actions={[
                    {
                      name: "View",
                      onClick: () => {
                        console.debug(`view: ${row._id}`)
                        history.push(`/users/${row._id}`)
                      }
                    }
                    // { name: "Edit",
                    //   onClick: () => {
                    //     console.debug("edit: " + row._id);
                    //     history.push(`/user/${row._id}/edit`);
                    //   }
                    // },
                    // { name: "Delete",
                    //   onClick: () => {
                    //     console.debug("delete: " + row._id)
                    //   }
                    // },
                  ]}
                />
              )
            }}
            data={
              currentTab === 0
                ? data.users
                : data.users.filter(
                    user => user.type === USER_TYPES[currentTab]
                  )
            }
          />
        )}
      </TableContainer>
    )
  }

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | User Access Control`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader title="Users" pageName="" />

      <Box className={classes.tabContainer}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />
          })}
        </Tabs>
        <Divider />
      </Box>

      <Fab
        color="secondary"
        aria-label="add device"
        className={classes.fab}
        onClick={() => setOpenAddUser(true)}
      >
        <AddIcon />
      </Fab>

      <Box className={classes.content}>
        <SwipeableViews
          index={currentTab}
          onChangeIndex={handleChangeTabIndex}
          animateTransitions={mobileView}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel className={classes.tabPanel}  value={currentTab} index={0}>
            {mobileView ? renderUsersList() : renderUsersTable()}
          </TabPanel>
          <TabPanel className={classes.tabPanel}  value={currentTab} index={1}>
            {mobileView ? renderUsersList() : renderUsersTable()}
          </TabPanel>
          <TabPanel className={classes.tabPanel}  value={currentTab} index={2}>
            {mobileView ? renderUsersList() : renderUsersTable()}
          </TabPanel>
        </SwipeableViews>
      </Box>
      <AddUserDialog open={openAddUser} onClose={() => setOpenAddUser(false)} />
      <UserClockoutDialog open={openUserClockout} onClose={() => setOpenUserClockout(false)} />
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(3)
    }
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  // dashboard: {
  //   height: `${100}%`,
  //   display: "flex",
  //   flexDirection: "column"
  // },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  // content: {
  //   height: `${100}%`,
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(12),
  //     marginRight: theme.spacing(3)
  //   }
  // },
  userList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  users: {
    // display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
    // maxWidth: 520 + "px"
  },
  // users: {
  //   // display: "flex",
  //   // flexDirection: "column",
  //   // [theme.breakpoints.up("sm")]: {
  //   //   marginLeft: theme.spacing(8),
  //   //   paddingLeft: theme.spacing(8),
  //   //   paddingRight: theme.spacing(8),
  //   // },
  //   // [theme.breakpoints.up("md")]: {
  //   //   flexDirection: "row",
  //   // }
  //   marginLeft: theme.spacing(8),
  //   alignItems: "center",
  //   [theme.breakpoints.down("xs")]: {
  //     marginLeft: theme.spacing(2),
  //     marginRight: theme.spacing(2),
  //   }
  // },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabContainer: {
    // height: `${100}%`,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0)
    }
  },
  tabs: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    minWidth: "inherit",
    marginRight: `${28}px`
  },
  fab: {
    display: "flex",
    position: "fixed",
    marginTop: `${-60}px`,
    right: `${30}px`,
    zIndex: 9999,
    [theme.breakpoints.down("xs")]: {
      marginTop: "inherit",
      // top: "auto"
      bottom: `${80}px`
    }
  }
}))

const hasRouterPage = withRouter(UsersView)
export default hasRouterPage
