import React, { useState, useEffect } from "react"
import { DateTime, Duration } from "luxon"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import _ from "lodash"

import { Box, CircularProgress, TableContainer } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import LuxonUtils from "@date-io/luxon"

import DataTable from "../components/common/DataTable"
import ContextMenu from "../components/common/ContextMenu"
import ReportForm from "../components/attendance/ReportForm"

import * as transactions from "../helpers/userTransactions"
import { signOut } from "../utils/userHelper"

const TRANSACTIONS = gql`
  query cardTransactions(
    $filterByTokens: [String]
    $includeAuthDevice: Boolean
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    cardTransactions(
      filterByTokens: $filterByTokens
      includeAuthDevice: $includeAuthDevice
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      _id
      token
      action
      reason
      authDevice {
        user {
          _id
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`

const useStyles = makeStyles(theme => ({
  tableContainer: {},
  progress: {
    display: "flex",
    justifyContent: "center",
    width: `${100}%`
  }
}))

// const renderReportTable = (classes) => {
//   return (
//     <TableContainer className={classes.tableContainer}>
//       <DataTable
//         pagination
//         paginationIntervals={[5, 10, 20, 50, 100]}
//         rowHoverHightlight
//         filterColumn={"['authDevice']['user']['firstName']"}
//         searchQuery=""
//         filterRange={{
//           date: {
//             start: DateTime.local()
//               .startOf("day")
//               .minus({ days: 2 })
//               .toISO(),
//             end: DateTime.local()
//               .endOf("day")
//               .toISO()
//           }
//         }}
//         headers={[
//           "#",
//           "Timestamp",
//           "First Name",
//           "Last Name",
//           "Action",
//           "Reason"
//         ]}
//         columns={[
//           {
//             type: "index"
//           },
//           {
//             type: "date",
//             value: "createdAt",
//             format: "dd/MM/yyyy, HH:mm"
//           },
//           {
//             type: "key",
//             value: "['authDevice']['user']['firstName']"
//           },
//           {
//             type: "key",
//             value: "['authDevice']['user']['lastName']"
//           },
//           {
//             type: "key",
//             value: "action"
//           },
//           {
//             type: "key",
//             value: "reason"
//           }
//         ]}
//         rowActions={row => {
//           return (
//             <ContextMenu
//               actions={[
//                 {
//                   name: "View User",
//                   onClick: () => {
//                     let userId = row.authDevice.user._id;
//                     console.debug("view: ", userId);
//                     history.push(`/user/${userId}`);
//                   }
//                 }
//               ]}
//             />
//           );
//         }}
//         data={data.cardTransactions}
//       />
//     </TableContainer>
//   );
// }

const ApplePen = ({ data }) => {
  return (
    <div>
      <h2>Daily Activity for startDate - endEnd</h2>
      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Duration</td>
          </tr>
        </thead>
        <tbody>
          {data.map(result => {
            return (
              <tr key={result.userId}>
                <td>{result.date}</td>
                <td>{result.firstName}</td>
                <td>{result.lastName}</td>
                <td>{result.duration}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const AttendanceReport = props => {
  const classes = useStyles(props)
  // const { authed, history, user } = props
  const [state, setState] = React.useState({
    goob: [],
    startDate: DateTime.local()
      .startOf("day")
      // .minus({ days: 7 })
      .minus({ days: 3 })
      .toISO(),
    endDate: DateTime.local()
      .endOf("day")
      .minus({ days: 1 })
      .toISO()
  })

  const { loading, error, data } = useQuery(TRANSACTIONS, {
    variables: {
      includeAuthDevice: true,
      startDate: state.startDate,
      endDate: state.endDate,
      limit: 9999
    }
  })

  const formatTransactions = async (cardTransactions) => {
    const goob = await transactions
      .orderByDateAndUser(cardTransactions)
      .then(res => transactions.calculateElapsedTime(res))
      .catch(err => {
        console.error(err)
        return []
      })
    setState({ ...state, goob })
  }

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (data !== undefined) {
      formatTransactions(data.cardTransactions)
    }
  }, [data])

  const handleReportFormSubmit = params => {
    setState({ ...state, startDate: params.startDate, endDate: params.endDate })
  }

  if (loading) return <CircularProgress className={classes.progress} />
  if (error) {
    if (error.message.includes("token is invalid")) {
      signOut()
    }
    return `Error! ${error}`
  }

  // return
  return (
    <Box>
      <ReportForm onSubmit={params => handleReportFormSubmit(params)} />
      {state.goob.length > 0 && (
        <Box>
          <hr />
          <ApplePen data={state.goob} />
        </Box>
      )}
    </Box>
  )
}

export default AttendanceReport
