import React from "react"
import { DateTime } from "luxon"

import { makeStyles } from "@material-ui/core/styles"
import CCTVItem from "./CCTVItem"

const CCTVDay = props => {
  const classes = useStyles(props)
  const { day } = props

  const today = DateTime.local().endOf("day")
  const transactionDay = DateTime.fromISO(day.date).endOf("day")
  const dayDiff = transactionDay.diff(today, "day")

  const customDay =
    dayDiff.days <= -2
      ? transactionDay.toFormat("cccc")
      : transactionDay.toRelativeCalendar()

  return (
    <div key={day.date}>
      <h3 className={classes.transactionDay}>{customDay}</h3>
      {day.transactions.map((transaction, index) => {
        return (
          <CCTVItem key={transaction._id} transaction={transaction} />
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  transactionDay: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase"
  }
}))

export default CCTVDay
