import React, { useState, useEffect } from "react";

import Helmet from "react-helmet";
import { Box, Tabs, Tab, Divider, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import MiniDrawer from "../components/common/MiniDrawer";
import PageHeader from "../components/common/PageHeader";

import UserProfile from "../components/UserProfile";
import UserAuthentication from "../components/UserAuthentication";
import UserPreferences from "../components/UserPreferences";

import SwipeableViews from "react-swipeable-views";

import { APP_NAME } from "../constants";
import TabPanel from "../components/common/TabPanel";

interface Props {
  authed: boolean;
  history: any;
  user: any;
}
interface State {}

const useStyles = makeStyles((theme) => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(3),
    },
    maxWidth: 520 + "px",
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  // dashboard: {
  //   height: `${100}%`,
  //   display: "flex",
  //   flexDirection: "column",
  // },
  // content: {
  //   height: `${100}%`,
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(12),
  //     marginRight: theme.spacing(3),
  //   },
  //   maxWidth: 520 + "px",
  // },
  tabContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  tabs: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
    },
  },
  tab: {
    minWidth: "inherit",
    marginRight: 28 + "px",
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
}));

const tabs = ["Profile", "Authentication", "Preferences"];

const SettingsView = (props: Props) => {
  const classes = useStyles(props);
  const { authed, history, user } = props;

  const mobileView = useMediaQuery("(max-width:599px)");

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#profile":
        return 0;
      case "#authentication":
        return 1;
      case "#preferences":
        return 2;
    }
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: any) => {
    history.push("#" + tabs[newTab].toLocaleLowerCase());
    setCurrentTab(newTab);
  };

  const handleChangeTabIndex = (index: number) => {
    setCurrentTab(index);
  };

  const loggedInUser = localStorage.getItem("currentUser") || "";
  const parsedUser = JSON.parse(loggedInUser);

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | Time & Attendance`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader title="Settings" pageName="Settings" />

      <Box className={classes.tabContainer}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          // variant="fullWidth"
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />;
          })}
        </Tabs>
        <Divider className={classes.divider} />
      </Box>
      <Box className={classes.content}>
        <SwipeableViews
          index={currentTab}
          onChangeIndex={handleChangeTabIndex}
          animateTransitions={mobileView}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel className={classes.tabPanel} value={currentTab} index={0}>
            <UserProfile user={parsedUser} ownProfile={true} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={1}>
            <UserAuthentication user={parsedUser} ownProfile={true} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={2}>
            <UserPreferences />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Container>
  );
};

export default SettingsView;
