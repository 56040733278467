import React, { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputBase,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Chip,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import clsx from "clsx";
import { DateTime } from "luxon";
import DateFnsUtils from "@date-io/date-fns";
import DateRangePicker from "../common/DateRangePicker";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  tableContainer: {},
  progress: {
    display: "flex",
    justifyContent: "center",
    width: `${100}%`
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  fullWidth: {
    minWidth: 100 + "%"
  },
  halfWidth: {
    width: 47.5 + "%"
  },
  listActions: {
    display: "flex",
    justifyContent: "center"
  },
  menuItem: {
    backgroundColor: "inherit!important"
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: "100%"
  },
  generateReportButton: {
    marginTop: theme.spacing(2),
    padding: 14 + "px"
  }
}));

interface Props {
  onSubmit: Function
}

interface State {
  loading: boolean;
  startDate: string;
  endDate: string;
  userType: string;
  users: any[];
  selectedUsers: any[];
  errorMessage: string;
}

interface DateRangePickerValues {
  begin: string;
  end: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const ReportForm = (props: Props) => {
  const classes = useStyles(props);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const userTypeLabel = React.useRef<HTMLLabelElement>(null);
  const usersLabel = React.useRef<HTMLLabelElement>(null);

  const [labelWidth, setLabelWidth] = React.useState({ users: 0, userType: 0 });
  React.useEffect(() => {
    setLabelWidth({
      userType: userTypeLabel.current!.offsetWidth,
      users: usersLabel.current!.offsetWidth
    });
  }, []);

  const [state, setState] = React.useState<State>({
    loading: false,
    startDate: DateTime.local()
      .startOf("day")
      .minus({ days: 7 })
      .toISO(),
    endDate: DateTime.local()
      .endOf("day")
      .toISO(),
    userType: "all",
    users: [
      "Bill",
      "Ted",
      "Rufus",
      "Kobi",
      "Lebron",
      "Micky",
      "Tobias",
      "Frankie",
      "Markus"
    ],
    selectedUsers: [],
    errorMessage: ""
  });

  const handleGenerateClick = () => {
    console.log("click!");
    props.onSubmit(state)
  };

  const handleChange = (prop: keyof State) => (
    event: any //React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, [prop]: event.target.value, errorMessage: "" });
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          Report Generator
        </Typography>
      </Box>
      <FormControl className={clsx(classes.formControl, classes.fullWidth)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateRangePicker
            label="Date Range"
            autoOk
            // value={[{ begin: state.startDate }, { end: state.endDate }]}
            value={[]}
            // value={"01/01/2020 - 01/02/2020"}
            inputVariant="outlined"
            variant={fullScreen ? "dialog" : "inline"}
            // InputAdornmentProps={{ position: "start" }}
            format="dd/MM/yyyy"
            onChange={(values: DateRangePickerValues) => {
              console.log("date range", values);
              setState({
                ...state,
                loading: false,
                startDate: values.begin,
                endDate: values.end
              });
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </FormControl>

      <FormControl
        variant="outlined"
        className={clsx(classes.formControl, classes.fullWidth)}
      >
        <InputLabel ref={userTypeLabel} id="user-type-label">
          User Type
        </InputLabel>
        <Select
          labelId="user-type-label"
          id="user-type-select"
          value={state.userType}
          onChange={handleChange("userType")}
          labelWidth={labelWidth.userType}
          fullWidth
        >
          <MenuItem value="all">
            <em>All</em>
          </MenuItem>
          <MenuItem value="employee">Employee</MenuItem>
          <MenuItem value="customer">Customer</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        required
        variant="outlined"
        className={clsx(classes.formControl, classes.fullWidth)}
      >
        <InputLabel ref={usersLabel} id="users-label">
          Users
        </InputLabel>
        <Select
          labelId="users-label"
          id="users"
          multiple
          value={state.selectedUsers}
          onChange={handleChange("selectedUsers")}
          labelWidth={labelWidth.users}
          // input={<Input id="select-multiple-chip" />}
          renderValue={(selectedUsers: any) => (
            <Box className={classes.chips}>
              {selectedUsers.map((value: string, index: number) => (
                <Chip key={index} label={value} className={classes.chip} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          fullWidth
        >
          {/* <Box className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </Box> */}
          {/* <Box className={classes.listActions}>
            <Button variant="text" color="primary">
              {state.selectedUsers == state.users
                ? "Deselect All"
                : "Select All"}
            </Button>
          </Box>
          <hr /> */}
          {state.users.map((user, index) => (
            <MenuItem
              key={index}
              value={user}
              classes={{ selected: classes.menuItem }}
            >
              <Checkbox checked={state.selectedUsers.indexOf(user) > -1} />
              <ListItemText primary={user} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        aria-label="generate report"
        onClick={() => handleGenerateClick()}
        className={classes.generateReportButton}
        disabled={state.loading}
        fullWidth
      >
        Generate Report
      </Button>
    </Box>
  );
};

export default ReportForm;
