import React from "react"
import { withRouter } from "react-router-dom"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Helmet from "react-helmet"
import { Box, Tabs, Tab, Divider, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import SwipeableViews from "react-swipeable-views"
import MiniDrawer from "../components/common/MiniDrawer"
import PageHeader from "../components/common/PageHeader"

import AttendanceGlance from "./AttendanceGlance"
import AttendanceReport from "./AttendanceReport"
import AttendanceCCTV from "./AttendanceCCTV"

import { APP_NAME } from "../constants"
import TabPanel from "../components/common/TabPanel"

const tabs = ["Glance", "Reports", "CCTV"]

const Attendance = props => {
  const classes = useStyles(props)
  const { authed, history, user } = props

  const mobileView = useMediaQuery("(max-width:599px)")

  const [currentTab, setCurrentTab] = React.useState(() => {
    switch (history.location.hash) {
      default:
      case "#glance":
        return 0
      case "#reports":
        return 1
      case "#cctv":
        return 2
    }
  })

  const handleTabChange = (event, newTab) => {
    history.push(`#${tabs[newTab].toLocaleLowerCase()}`)
    setCurrentTab(newTab)
  }

  const handleChangeTabIndex = index => {
    setCurrentTab(index)
  }

  return (
    <Container className={classes.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${APP_NAME} | Time & Attendance`}</title>
      </Helmet>

      <MiniDrawer history={history} authed={authed} user={user} />
      <PageHeader title="Attendance" pageName="" />

      <Box className={classes.tabContainer}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="tabs"
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} className={classes.tab} label={tab} />
          })}
        </Tabs>
        <Divider />
      </Box>

      <Box className={classes.content}>
        <SwipeableViews
          index={currentTab}
          onChangeIndex={handleChangeTabIndex}
          animateTransitions={mobileView}
          className={classes.slideContainer}
          slideClassName={classes.slideContainer}
          containerStyle={{ flexGrow: 1 }}
        >
          <TabPanel value={currentTab} index={0}>
            <AttendanceGlance />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <AttendanceReport history={history} authed={authed} user={user} />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <AttendanceCCTV history={history} authed={authed} user={user} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginBottom: `${55}px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(3)
    }
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tabContainer: {
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0)
    }
  },
  tabs: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    minWidth: "inherit",
    marginRight: `${28}px`
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    width: `${100}%`
  }
}))

const hasRouterPage = withRouter(Attendance)
export default hasRouterPage
