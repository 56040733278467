import React from "react"
import { Box, Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import useLongPress from '../common/UseLongPress';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    marginBottom: theme.spacing(2)
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0
  },
  name: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    alignSelf: "center"
  },
  action: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic",
    fontSize: `${0.8}em`
  },
  time: {
    color: theme.palette.text.primary
  },
  arrowLeft: {
    color: theme.palette.error.dark
  },
  arrowRight: {
    color: theme.palette.success.dark
  }
}))

const UserListItem = props => {
  const classes = useStyles(props)
  const { _id, clockedIn, image, firstName, lastName } = props.user

  const icon = clockedIn ? (
    <ArrowForwardIcon className={classes.arrowRight} />
  ) : (
    <ArrowBackIcon className={classes.arrowLeft} />
  )

  const onLongPress = useLongPress(props.onLongPress, 1000);

  return (
    <Box
      key={_id}
      className={classes.container}
      onClick={user => props.onClick(user)}
      {...onLongPress}
    >
      <Avatar className={classes.image} src={image} />
      <ul className={classes.list}>
        <li className={classes.name}>
          {firstName} <b>{lastName}</b>
        </li>
        <li className={classes.action}>
          {icon} {clockedIn ? "Clocked In" : "Clocked Out"}
        </li>
      </ul>
    </Box>
  )
}

export default UserListItem
