import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import { useSocket } from "use-socketio"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { FormHelperText, Typography } from "@material-ui/core"
import DataDialog from "./common/DataDialog.tsx"

const ADD_TOKEN = gql`
  mutation addAuthDevice($secret: String!, $type: String!, $userId: String!) {
    addAuthDevice(secret: $secret, type: $type, userId: $userId) {
      _id
    }
  }
`

const CARD_TYPES = ["card", "keyfob", "octopus", "phone", "hkid"]

const AddAuthDeviceDialog = props => {
  const classes = useStyles()

  const [values, setValues] = React.useState({
    loading: false,
    success: false,
    deviceToken: "",
    deviceType: CARD_TYPES[0],
    showDeviceToken: false,
    errorMessage: null
  })

  const socket = useSocket("nfc-card-scanned", uid =>
    setValues({ ...values, deviceToken: uid, errorMessage: null })
  )

  const [addAuthDevice, { data }] = useMutation(ADD_TOKEN)

  const handleClose = () => {
    props.onClose()
    setValues({
      ...values,
      deviceToken: "",
      deviceType: CARD_TYPES[0],
      success: false,
      loading: false,
      showDeviceToken: false,
      errorMessage: null
    })
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, errorMessage: null })
  }

  const handleClickDeviceToken = () => {
    setValues({ ...values, showDeviceToken: !values.showDeviceToken })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleAddAuthDevice = () => {
    setValues({ ...values, loading: true })
    addAuthDevice({
      variables: {
        secret: values.deviceToken,
        type: values.deviceType,
        userId: props.userId
      }
    })
      .then(res => {
        console.log("data", res.data)
        setValues({
          ...values,
          deviceToken: "",
          deviceType: CARD_TYPES[0],
          errorMessage: null,
          loading: false,
          success: true
        })
      })
      .catch(err => {
        console.error({ err })
        let message = ""
        if (err.graphQLErrors.length > 0) {
          message = err.graphQLErrors[0].message
        } else {
          message = err.message
        }
        setValues({
          ...values,
          errorMessage: message,
          loading: false
        })
      })
  }

  if (props.open) {
    return (
      <DataDialog
        open
        title="Add Auth Device"
        content={
          !values.success ? (
            <div>
              <Typography>
                Please scan the NFC device or manually enter the device token.
              </Typography>
              <form className={classes.formContainer}>
                <FormControl
                  error={!!values.errorMessage}
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="standard-adornment-device">
                    Device Token
                  </InputLabel>
                  <Input
                    id="standard-adornment-deviceToken"
                    type={values.showDeviceToken ? "text" : "password"}
                    value={values.deviceToken}
                    onChange={handleChange("deviceToken")}
                    autoComplete="false"
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle token visibility"
                          onClick={handleClickDeviceToken}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showDeviceToken ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="deviceToken-helper-text">
                    {values.errorMessage}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel id="device-type-select-label">Type</InputLabel>
                  <Select
                    labelId="device-type-select-label"
                    id="device-type-select"
                    value={values.deviceType}
                    fullWidth
                    onChange={event =>
                      setValues({ ...values, deviceType: event.target.value })
                    }
                  >
                    {CARD_TYPES.map((type, index) => {
                      return (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </form>
            </div>
          ) : (
            <Typography>Auth device added successfully :-)</Typography>
          )
        }
        actions={[
          {
            name: "Save",
            disabled: values.success,
            primary: true,
            loading: values.loading,
            onClick: () => handleAddAuthDevice()
          },
          {
            name: !values.success ? "Cancel" : "Close",
            primary: false,
            onClick: () => handleClose()
          }
        ]}
      />
    )
  }
  return null
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {},
  formContainer: {
    display: "flex",
    flexDirection: "column"
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {}
}))

export default AddAuthDeviceDialog
