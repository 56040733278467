import React from "react"
import { DateTime } from "luxon"

import { Box, Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"

import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import LazyLoad from "react-lazyload";
import ReactPlayer from 'react-player/lazy'

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const CCTVItem = props => {
  const classes = useStyles(props)

  const { transaction } = props
  const { createdAt } = transaction

  const time = DateTime.fromISO(createdAt).toFormat("T") // createdAt.split('T')[1].split('.')[0];

  return (
    <Box key={transaction._id} className={classes.container}>
      <ul className={classes.list}>
        <li className={classes.time}>{time}</li>
        <LazyLoad height={250} once>
          <ReactPlayer
            controls={true}
            light={transaction.thumbnailUrl}
            className='react-player'
            width={400 + 'px'}
            height={448 + 'px'}
            url={transaction.videoUrl} />
        </LazyLoad>
      </ul>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderWidth: `${1}px`,
    borderRadius: `${5}px`
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0
  },
  name: {
    color: theme.palette.text.primary
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    alignSelf: "center"
  },
  action: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic"
  },
  time: {
    color: theme.palette.text.primary
  },
  arrowLeft: {
    color: theme.palette.error.dark
  },
  arrowRight: {
    color: theme.palette.success.dark
  },
  button: {
    marginRight: theme.spacing(2)
  },
  "react-player": {
    position: "absolute",
    top: 0,
    left: 0
  }
}))

export default CCTVItem