import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Box, Typography, IconButton, Fab, Container } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import SaveIcon from "@material-ui/icons/Save"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import DataTable from "./common/DataTable.tsx"
import AddAuthDeviceDialog from "./AddAuthDeviceDialog"
import DataDialog from "./common/DataDialog.tsx"

const DELETE_AUTH_DEVICE = gql`
  mutation deleteAuthDevice($_id: ID!) {
    deleteAuthDevice(_id: $_id) {
      _id
    }
  }
`

const UserAuthDevices = props => {
  const classes = useStyles(props)
  const { user } = props
  const [authDevices, setAuthDevices] = useState(user.authDevices || [])
  const [edit, setEdit] = useState(true)
  const [openAddUserDevice, setOpenAddUserDevice] = useState(false)
  const [state, setState] = useState({
    errorMessage: null,
    alert: false,
    loading: false,
    deleteDialogOpen: false,
    selectedRow: null,
    selectedRowIndex: null
  })

  const [deleteAuthDevice, { data }] = useMutation(DELETE_AUTH_DEVICE)

  return (
    <Container className={classes.content}>
      <Fab
        color="secondary"
        aria-label="add device"
        className={classes.fab}
        onClick={() => setOpenAddUserDevice(true)}
      >
        <AddIcon />
      </Fab>
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          Devices
        </Typography>
      </Box>
      <Box className={classes.devices}>
        <DataTable
          rowHoverHightlight={false}
          headers={["Type", "Secret"]}
          columns={[
            {
              type: "key",
              value: "type"
            },
            {
              type: "string",
              value: "········"
            }
          ]}
          rowActions={(row, rowIndex) => {
            if (edit) {
              return (
                <IconButton
                  aria-label="edit devices"
                  onClick={() => {
                    setState({
                      ...state,
                      deleteDialogOpen: true,
                      selectedRow: row,
                      selectedRowIndex: rowIndex
                    })
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )
            }
          }}
          data={authDevices}
        />
      </Box>
      <AddAuthDeviceDialog
        userId={user._id}
        open={openAddUserDevice}
        onClose={() => setOpenAddUserDevice(false)}
      />
      <DataDialog
        open={state.deleteDialogOpen}
        title="Delete Auth Device"
        content="Are you sure you you want to delete this device?"
        actions={[
          {
            name: "Yes, Delete",
            loading: false,
            primary: false,
            onClick: () => {
              setState({ ...state, loading: true })
              deleteAuthDevice({
                variables: { _id: state.selectedRow._id }
              }).then(res => {
                authDevices.splice(state.selectedRowIndex - 1, 1)
                setState({
                  ...state,
                  selectedRow: null,
                  selectedRowIndex: null,
                  deleteDialogOpen: false
                })
              })
            }
          },
          {
            name: "Cancel",
            loading: false,
            primary: true,
            onClick: () => setState({ ...state, deleteDialogOpen: false })
          }
        ]}
      />
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: theme.spacing(4),
    //   paddingRight: theme.spacing(4),
    //   marginLeft: theme.spacing(4)
    // }
    // maxWidth: 520 + "px"
  },
  fab: {
    alignSelf: "flex-end",
    // display: "flex",
    // position: "fixed",
    // marginTop: `${-60}px`,
    marginTop: `${-28}px`,
    // right: `${30}px`,
    zIndex: 9999,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: `${30}px`
      // marginTop: "inherit",
      // top: "auto"
      // bottom: `${80}px`
    }
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
    // [theme.breakpoints.down("xs")]: {
    //   marginLeft: theme.spacing(2)
    // }
  },
  devices: {
    alignItems: "center",
    maxWidth: `${520}px`,
    // flexGrow: 1,
    // position: "absolute",
    // marginBottom: `${30}px`,
    [theme.breakpoints.down("xs")]: {
      // position: "relative",
      // marginLeft: theme.spacing(2),
      // marginRight: theme.spacing(2)
      // marginBottom: `${30}px`
    }
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    marginRight: theme.spacing(2)
  },
  imageUploadOverlay: {
    position: "absolute",
    zIndex: 2,
    background: "rgba(0, 0, 0, 0.2)",
    color: "rgba(0,0,0,0.5)",
    width: `${100}px`,
    height: `${100}px`,
    borderRadius: `${50}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))

export default UserAuthDevices
