import React from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import List from "@material-ui/core/List"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Menu, MenuItem } from "@material-ui/core"

import DashboardIcon from "@material-ui/icons/Dashboard"
import HomeIcon from "@material-ui/icons/Home"
import AccountCircle from "@material-ui/icons/AccountCircle"
import UserControlIcon from "@material-ui/icons/SupervisedUserCircle"
import AVTimerIcon from "@material-ui/icons/AvTimer"

import { APP_NAME } from "../../constants"
import { hasRole } from "../../utils/userHelper"
import ChangePasswordDialog from "../ChangePasswordDialog"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  miniDrawer: {
    display: "flex"
  },
  appBar: {
    color: theme.palette.background.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarTop: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  appBarBottom: {
    // position: "absolute",
    top: "auto",
    bottom: 0,
    width: `${100}%`,
    zIndex: 99999,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbarWithTabs: {
    justifyContent: "space-around"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  listItemIcon: {
    paddingLeft: `${8}px`
  },
  tabBarItem: {},
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  headerMargin: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    }
  }
}))

export default function MiniDrawer(props) {
  const classes = useStyles(props)
  const theme = useTheme(props)
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)
  const [openChangePassword, setOpenChangePassword] = React.useState(false)
  const { title, authed, history, user } = props

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  const handleNavigationClick = item => {
    // do stuff
    item.onClick()
  }

  function handleSignOut() {
    localStorage.removeItem("token")
    localStorage.removeItem("refresh_token")
    window.location.replace("/")
  }

  // function handleChangePassword() {
  //   setOpenChangePassword(true)
  //   handleMenuClose()
  // }

  const menuItems = [
    {
      onClick: () => history.push("/"),
      icon: <HomeIcon />,
      text: "Dashboard"
    },
    {
      onClick: () => history.push("/users"),
      icon: <UserControlIcon />,
      text: "User Access Control"
    },
    {
      onClick: () => history.push("/attendance"),
      icon: <AVTimerIcon />,
      text: "Time & Attendance"
    },
    {
      onClick: () => history.push("/settings"),
      icon: <AccountCircle />,
      text: "Profile & Settings"
    }
  ]

  const renderToolbarTop = () => {
    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, classes.appBarTop, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {`${APP_NAME}`} {title ? ` | ${title}` : null}
          </Typography>

          <div className={classes.grow} />

          {authed && (
            <div className={classes.dropMenu}>
              <IconButton
                aria-owns={menuOpen ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => history.push("/settings#profile")}>
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => history.push("/settings#authentication")}
                >
                  Authentication
                </MenuItem>
                <MenuItem onClick={() => history.push("/settings#preferences")}>
                  Preferences
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
        <ChangePasswordDialog
          open={openChangePassword}
          onClose={() => setOpenChangePassword(false)}
        />
      </AppBar>
    )
  }

  const renderToolbarBottom = () => {
    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, classes.appBarBottom)}
      >
        <Toolbar className={classes.toolbarWithTabs}>
          {menuItems.map((item, index) => (
            <IconButton
              key={index}
              color="inherit"
              className={classes.tabBarItem}
              onClick={item.onClick}
            >
              {item.icon}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>

      // <BottomNavigation
      //   value={"Dashboard"}
      //   onChange={console.log}
      //   // className={classes.miniDrawer}
      //   className={clsx(classes.appBarBottom)}
      //   // className={classes.toolbarWithTabs}
      // >
      //   {menuItems.map((item, index) => (
      //     <BottomNavigationAction
      //       label={item.text}
      //       value={item.text}
      //       icon={item.icon}
      //     />
      //     // <IconButton
      //     //   key={index}
      //     //   color="inherit"
      //     //   className={classes.tabBarItem}
      //     //   onClick={item.onClick}
      //     // >
      //     //   {item.icon}
      //     // </IconButton>
      //   ))}
      // </BottomNavigation>
    )
  }

  return (
    <div className={classes.miniDrawer}>
      <CssBaseline />

      {renderToolbarTop()}
      {renderToolbarBottom()}

      {/* -- Start: Drawer -- */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuItems.map((item, index) => {
            return (
              <ListItem
                button
                onClick={() => handleNavigationClick(item)}
                key={index}
                // selected={true}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <div className={classes.headerMargin} />
    </div>
  )
}
