import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import Alert from "@material-ui/lab/Alert"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      _id
    }
  }
`

const useStyles = makeStyles(theme => ({
  content: {},
  alert: {
    marginBottom: theme.spacing(2)
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formContainer: {
    // display: "flex",
    // flexDirection: "row",
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column"
    // }
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    minWidth: `${100}%`
  },
  halfWidth: {
    width: `${47.5}%`
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  input: {
    display: "none"
  },
  submitButton: {
    marginTop: theme.spacing(2),
    padding: `${14}px`
  }
}))

const ChangePassword = props => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    loading: false,
    success: false,
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    showPassword: false,
    errorField: null,
    errorMessage: null
  })

  const currentPasswordLabel = React.useRef(null)
  const [
    currentPasswordLabelWidth,
    setCurrentPasswordLabelWidth
  ] = React.useState(0)

  const newPasswordLabel = React.useRef(null)
  const [
    newPasswordLabelWidth,
    setNewPasswordLabelWidth
  ] = React.useState(0)

  const newPasswordConfirmLabel = React.useRef(null)
  const [
    newPasswordConfirmLabelWidth,
    setNewPasswordConfirmLabelWidth
  ] = React.useState(0)

  React.useEffect(() => {
    setCurrentPasswordLabelWidth(currentPasswordLabel.current.offsetWidth)
    setNewPasswordLabelWidth(newPasswordLabel.current.offsetWidth)
    setNewPasswordConfirmLabelWidth(newPasswordConfirmLabel.current.offsetWidth)
  }, []);

  const [changePassword, { data }] = useMutation(CHANGE_PASSWORD)

  const handleChange = prop => event => {
    setState({
      ...state,
      [prop]: event.target.value,
      errorMessage: null,
      errorField: null,
      alert: false
    })
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleChangePassword = () => {
    setState({ ...state, loading: true })
    if (state.newPassword === state.newPasswordConfirm) {
      changePassword({
        variables: {
          currentPassword: state.currentPassword,
          newPassword: state.newPassword
        }
      })
        .then(() => {
          setState({
            ...state,
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            errorMessage: null,
            errorField: null,
            loading: false,
            success: true,
            alert: true,
            message: "Password updated!"
          })
        })
        .catch(err => {
          let message = ""
          if (err.graphQLErrors.length > 0) {
            message = err.graphQLErrors[0].message
          } else {
            message = err.message
          }
          setState({
            ...state,
            errorMessage: message,
            errorField: 1,
            alert: true,
            loading: false
          })
        })
    } else {
      setState({
        ...state,
        errorMessage: "New Password and Confirm Password do not match!",
        errorField: 3,
        alert: true,
        loading: false
      })
    }
  }

  return (
    <Box>
      {state.alert && (
        <Alert
          severity={state.errorMessage ? "error" : "success"}
          color={state.errorMessage ? "error" : "success"}
          className={classes.alert}
        >
          {state.errorMessage ? state.errorMessage : state.message}
        </Alert>
      )}
      <form className={classes.formContainer} noValidate autoComplete="off">
        <FormControl
          error={state.errorField === 1}
          className={clsx(classes.formControl)}
          variant="outlined"
          fullWidth
        >
          <InputLabel
            ref={currentPasswordLabel}
            htmlFor="standard-adornment-currentpassword"
          >
            Current Password
          </InputLabel>
          <OutlinedInput
            id="standard-adornment-currentpassword"
            type={state.showPassword ? "text" : "password"}
            value={state.currentPassword}
            onChange={handleChange("currentPassword")}
            fullWidth
            labelWidth={currentPasswordLabelWidth}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl
          error={state.errorField === 2}
          className={clsx(classes.formControl)}
          variant="outlined"
          fullWidth
        >
          <InputLabel
            ref={newPasswordLabel}
            htmlFor="standard-adornment-newpassword"
          >
            New Password
          </InputLabel>
          <OutlinedInput
            id="standard-adornment-newpassword"
            type={state.showPassword ? "text" : "password"}
            value={state.newPassword}
            onChange={handleChange("newPassword")}
            labelWidth={newPasswordLabelWidth}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl
          error={state.errorField === 3}
          className={clsx(classes.formControl)}
          variant="outlined"
          fullWidth
        >
          <InputLabel
            ref={newPasswordConfirmLabel}
            htmlFor="standard-adornment-newpassword-confirm"
          >
            Confirm New Password
          </InputLabel>
          <OutlinedInput
            id="standard-adornment-newpassword-confirm"
            type={state.showPassword ? "text" : "password"}
            value={state.newPasswordConfirm}
            onChange={handleChange("newPasswordConfirm")}
            labelWidth={newPasswordConfirmLabelWidth}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          aria-label="change password"
          onClick={() => handleChangePassword()}
          className={classes.submitButton}
          disabled={state.loading}
          fullWidth
        >
          Change Password
        </Button>
      </form>
    </Box>
  )
}

export default ChangePassword
